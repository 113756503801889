import { fromJS } from 'immutable';
import actions from './actions';

const initState = fromJS({
  tutorials: false
});

export default function TutorialReducer(state = initState, action) {
  switch (action.type) {
    case actions.LIST_TUTORIAL_SUCCESS:
      return state.set('tutorials', action.tutorials);
    default:
      return state;
  }
}
