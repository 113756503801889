import Auth from "./auth/reducer";
import Projects from "./Projects/reducer";
import Studio from "./Studio/reducer";
import Users from "./Users/reducer";
import App from "./app/reducer";
import Contact from "./contacts/reducer";
import Settings from "./Settings/reducer";
import LanguageSwitcher from './languageSwitcher/reducer';

export default {
  Auth,
  Projects,
  Studio,
  Users,
  Settings,
  App,
  Contact,
  LanguageSwitcher
};
