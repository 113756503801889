import { all, call, takeEvery, put, fork } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  getToken,
  getProfile,
  clearToken,
  getDefaultRoute
} from 'src/utils/utility';
import actions from './actions';
import API from 'src/utils/API';

export function* loginRequest() {
  yield takeEvery('LOGIN_REQUEST', function* (action) {
    try {
      const login = yield call(API.auth.login, action.payload);
      /*if (action.payload.rememberme) {
        localStorage.setItem('cachedUsername', action.payload.username);
        localStorage.setItem('cachedUserId', action.payload.password);
      }*/

      if (login && login.token) {
        if (login.qrcode) {
          yield put({
            type: actions.LOGIN_QRCODE,
            qrcode: login.qrcode,
            token: login.token
          });
        } else {
          yield put({ type: actions.LOGIN_OTPCODE, token: login.token });
        }
      } else {
        yield put({ type: actions.LOGIN_ERROR });
      }
    } catch (err) {
      yield call(action.onError, { submit: err.error });
    }
  });
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function* (payload) {
    try {
      yield localStorage.setItem('id_token', payload.token);
      let userPreferences = yield call(
        API.auth.userPreferences,
        payload.profile._id
      );
      //console.log('userPreferences', userPreferences);
      if (!userPreferences) {
        const tutorials = [
          {
            screenId: 'onboard',
            doNotShow: false
          },
          {
            screenId: 'translation',
            doNotShow: false
          },
          {
            screenId: 'taking',
            doNotShow: false
          },
          {
            screenId: 'casting',
            doNotShow: false
          },
          {
            screenId: 'studio',
            doNotShow: false
          },
          {
            screenId: 'qa',
            doNotShow: false
          },
          {
            screenId: 'castReview',
            doNotShow: false
          },
          {
            screenId: 'textAdjust',
            doNotShow: false
          }
        ];
        payload.profile.preferences = {};
        payload.profile.preferences.tutorials = tutorials;
      } else {
        payload.profile.preferences = {};
        payload.profile.preferences.tutorials = userPreferences.tutorials;
      }

      yield localStorage.setItem('profile', JSON.stringify(payload.profile));
      // yield localStorage.setItem('preferences', JSON.stringify(userPreferences));
    } catch (err) {
      console.log(err);
    }
  });
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function* () {});
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function* () {
    clearToken();
    yield put(push('/'));
  });
}

export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function* () {
    const token = getToken().get('idToken');
    const profile = getProfile().get('profile');

    if (token && profile) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token,
        profile
      });
    }
  });
}

export function* loginQrCode() {
  yield takeEvery(actions.LOGIN_QRCODE, function* (payload) {
    yield put(
      push(`/password/set/${payload.token}/qrcode`, { qrcode: payload.qrcode })
    );
  });
}

export function* loginOTPCode() {
  yield takeEvery(actions.LOGIN_OTPCODE, function* (payload) {
    yield put(push(`/password/set/${payload.token}/otpcode`));
  });
}

export function* loginOTP() {
  yield takeEvery(actions.LOGIN_OTP_REQUEST, function* (action) {
    try {
      const otp = yield call(API.auth.verifyOTP, action.payload);
      if (otp && otp.token) {
        yield put({
          type: actions.LOGIN_SUCCESS,
          token: otp.token,
          profile: otp.user
        });
        const defaultRoute = getDefaultRoute(otp.user.role.handle);
        yield put(push(defaultRoute));
      } else {
        yield call(action.onError, otp.message);
      }
    } catch (e) {
      yield call(action.onError, e.message);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    fork(loginSuccess),
    fork(loginError),
    fork(logout),
    fork(loginQrCode),
    fork(loginOTPCode),
    fork(loginOTP)
  ]);
}
