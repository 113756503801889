import { fromJS } from 'immutable';
import actions from './actions';

const initState = fromJS({
  roles: [],
  users: null,
  user: null
});

export default function ProjectReducer(state = initState, action) {
  switch (action.type) {
    case actions.LIST_USER_ROLES_SUCCESS:
      return state.set('roles', action.roles);
    case actions.LIST_USERS_BY_ROLE_SUCCESS:
    case actions.LIST_ALL_USERS_SUCCESS:
      return state.set('users', action.users);
    case actions.LIST_USER_SUCCESS:
      return state.set('user', action.user);
    default:
      return state;
  }
}
