const actions = {
  LIST_TUTORIALS: 'LIST_TUTORIALS',
  LIST_TUTORIAL_SUCCESS: 'LIST_TUTORIAL_SUCCESS',
  UPLOAD_VIDEO: 'UPLOAD_VIDEO',
  UPDATE_PREFERENCE: 'UPDATE_PREFERENCE',
  
  listTutorials: () => ({
    type: actions.LIST_TUTORIALS,
  }),
  uploadVideo: (payload, screenId, active, file, cb) => ({
    type: actions.UPLOAD_VIDEO,
    payload,
    screenId,
    active,
    file,
    cb
  }),
  updatePreference: (payload, userId) => ({
    type: actions.UPDATE_PREFERENCE,
    payload,
    userId,
  })
};
export default actions;
