import { getCurrentLanguage } from 'src/components/containers/LanguageSwitcher/config';
const actions = {
  CHANGE_LANGUAGE: 'CHANGE_LANGUAGE',
  SET_LANGUAGE: 'SET_LANGUAGE',
  ACTIVATE_LANG_MODAL: 'ACTIVATE_LANG_MODAL',
  switchActivation: () => ({
    type: actions.ACTIVATE_LANG_MODAL
  }),
  changeLanguage: (language, userId) => {
    return {
      type: actions.CHANGE_LANGUAGE,
      language: getCurrentLanguage(language),
      userId
    };
  },
  setAppLang: (language) => {
    return {
      type: actions.SET_LANGUAGE,
      language: getCurrentLanguage(language)
    };
  }
};
export default actions;
