import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import {
  Avatar,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  SvgIcon,
  Tooltip,
  Typography,
  makeStyles,
  Badge
} from '@material-ui/core';
import {
  Bell as BellIcon,
  Package as PackageIcon,
  MessageCircle as MessageIcon,
  Truck as TruckIcon
} from 'react-feather';
import { socket } from '../../common/socket';
import IntlMessages from 'src/utils/intlMessages';
import { getProfile } from 'src/redux/auth/selectors';
import {
  getNotifications,
  getNotificationCount
} from 'src/redux/Projects/selectors';
import actions from 'src/redux/Projects/actions';

const { readNotification, clearAllNotifications, listNotifications } = actions;

const iconsMap = {
  order_placed: PackageIcon,
  new_message: MessageIcon,
  item_shipped: TruckIcon
};

const useStyles = makeStyles((theme) => ({
  popover: {
    width: 320
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  }
}));

const Notifications = () => {
  const classes = useStyles();
  const user = useSelector(getProfile());
  const notifiCount = useSelector(getNotificationCount());
  const notifications = useSelector(getNotifications());
  const ref = useRef(null);
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (user && user._id) {
      socket.on(`${user._id} NOTIFICATION_UPDATED`, (res) => {
        dispatch(listNotifications());
      });
    }

    dispatch(listNotifications());
  }, [dispatch, user]);

  const clearAll = (e) => {
    e.preventDefault();
    dispatch(clearAllNotifications());
  };

  const readNotificationFun = (nId, character) => {
    const payload = {
      notification_id: nId,
      isRead: true
    };
    dispatch(readNotification(payload));

    const currentNotifId = nId;
    localStorage.setItem('notification_id', currentNotifId);
    if (character._id) {
      localStorage.setItem('currentCharacter', character._id);
    } else {
      localStorage.setItem('currentCharacter', character);
    }
  };

  const redirectLink = (notification) => {
    const idProject = notification.project && notification.project._id;
    const stateProject = notification.project && notification.project.state;

    let link = '';
    if (notification.title === 'Translation completed') {
      link = `/projects/${idProject}/translate`;
    } else if (stateProject === 'PENDING_CAST_REVIEW') {
      link = `/projects/${idProject}/casting`;
    } else if (notification.title === 'Retakes added on your queue') {
      link = `/projects/retakes`;
    } else if (
      notification.character &&
      notification.character.state === 'PENDING_QA' &&
      user &&
      user.role.title === 'Director'
    ) {
      link = `/projects/${idProject}/studioQA`;
    } else if (
      stateProject === 'PENDING_TEXT_REVIEW' &&
      user &&
      user.role.title === 'Director'
    ) {
      link = `/projects/${idProject}/translate`;
    } else if (
      notification.character &&
      notification.character.state === 'PENDING_TECH' &&
      user &&
      user.role.title === 'Tech Mixer'
    ) {
      link = `/projects/${idProject}/studioQA`;
    } else if (notification.isRejected === true) {
      link = '/projects/doNotExist';
    } else if (user.role.handle === 'VOICEACTOR' && notification.character) {
      link = `/projects/${idProject}/${notification.character._id}/${notification._id}`;
    } else {
      link = `/projects/${idProject}`;
    }
    return link;
  };

  return (
    <>
      <Tooltip title="Notifications">
        <Badge color="secondary" overlap="circle" badgeContent={notifiCount}>
          <IconButton color="inherit" ref={ref} onClick={handleOpen}>
            <SvgIcon>
              <BellIcon />
            </SvgIcon>
          </IconButton>
        </Badge>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Box p={2}>
          <Typography variant="h5" color="textPrimary">
            <IntlMessages id="topbar.notification" />
          </Typography>
        </Box>
        {/* {!notifications ? ( */}
        {!notifications || notifications.length === 0 ? (
          <Box p={2}>
            <Typography variant="h6" color="textPrimary">
              <IntlMessages id="topbar.noNotification" />
            </Typography>
          </Box>
        ) : (
          ''
        )}

        {notifications && notifications.length > 0 ? (
          <>
            <List disablePadding>
              {notifications.map((notification) => {
                const Icon = iconsMap[notification.type || 'new_message'];

                return (
                  <ListItem
                    component={RouterLink}
                    divider
                    key={notification._id}
                    to={redirectLink(notification)}
                    onClick={() => {
                      readNotificationFun(
                        notification._id,
                        notification.character
                      );
                      handleClose();
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar className={classes.icon}>
                        <SvgIcon fontSize="small">
                          <Icon />
                        </SvgIcon>
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={notification.title}
                      primaryTypographyProps={{
                        variant: 'subtitle2',
                        color: 'textPrimary'
                      }}
                      secondary={
                        notification.project ? notification.project.title : ''
                      }
                    />
                  </ListItem>
                );
              })}
            </List>
            <Box p={1} display="flex" justifyContent="center">
              <Button size="small" onClick={(e) => clearAll(e)}>
                <IntlMessages id="topbar.clearAll" />
              </Button>
            </Box>
          </>
        ) : (
          ''
        )}
      </Popover>
    </>
  );
};

export default Notifications;
