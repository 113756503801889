const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGOUT: 'LOGOUT',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  LOGIN_QRCODE: 'LOGIN_QRCODE',
  LOGIN_OTPCODE: 'LOGIN_OTPCODE',
  LOGIN_OTP_REQUEST: 'LOGIN_OTP_REQUEST',
  CHANGE_AVATAR: 'CHANGE_AVATAR',
  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
  login: (payload, onError) => ({
    type: actions.LOGIN_REQUEST,
    payload,
    onError
  }),
  verifyOTP: (payload, onError) => ({
    type: actions.LOGIN_OTP_REQUEST,
    payload,
    onError
  }),
  logout: () => ({
    type: actions.LOGOUT
  }),
  changeAvatar: (payload) => ({
    type: actions.CHANGE_AVATAR,
    payload
  })
};
export default actions;
