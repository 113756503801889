import React, { useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import IntlMessages from 'src/utils/intlMessages';
import authAction from 'src/redux/auth/actions';
import { getProfile } from 'src/redux/auth/selectors';

import {
  Avatar,
  Box,
  ButtonBase,
  Hidden,
  Menu,
  MenuItem,
  Typography,
  Badge,
  makeStyles,
  withStyles
} from '@material-ui/core';
import getInitials from 'src/utils/getInitials';

const { logout } = authAction;

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""'
    },
    right: '28%'
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0
    }
  }
}))(Badge);

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1),
    fontSize: '0.9rem'
  },
  popover: {
    width: 200
  }
}));

const Account = () => {
  const classes = useStyles();
  const ref = useRef(null);
  const user = useSelector(getProfile());
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const preLogout = () => {
    //Para poder enviar el último tiempo del actor en Studio antes de perder el token
    setTimeout(() => {
      dispatch(logout());
    }, 100);
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        ref={ref}
      >
        <StyledBadge
          overlap="circle"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          variant="dot"
        >
          <Avatar alt="User" className={classes.avatar} src={user.avatar}>
            {getInitials(`${user.firstName} ${user.lastName}`)}
          </Avatar>
        </StyledBadge>

        <Hidden smDown>
          <Typography variant="h6" color="inherit">
            {user.firstName} {user.lastName}
          </Typography>
        </Hidden>
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem component={RouterLink} to={`/profile/${user._id}`}>
          Cuenta
        </MenuItem>
        <MenuItem onClick={preLogout}>
          <IntlMessages id="topbar.logout" />
        </MenuItem>
      </Menu>
    </>
  );
};

export default Account;
