/**
 * Project selectors
 */

import { createSelector } from 'reselect';
import moment from 'moment';

const selectProjects = (state) => state.Projects;
const selectApp = (state) => state.App;

const getDrawer = () =>
  createSelector(selectApp, (projectState) => projectState.get('openDrawer'));

const getCollapsed = () =>
  createSelector(selectApp, (projectState) => projectState.get('collapsed'));

const getProjects = () =>
  createSelector(selectProjects, (projectState) =>
    projectState.get('projects')
  );

const getFilter = () =>
  createSelector(selectProjects, (projectState) => projectState.get('filter'));

const getText = () =>
  createSelector(selectProjects, (projectState) => projectState.get('text'));

const getProjectsFiltered = (properties = ['title', 'description']) =>
  createSelector(
    getProjects(),
    getFilter(),
    (items, query) =>
      items &&
      items.filter((item) => {
        let matches = true;

        if (query) {
          let containsQuery = false;

          properties.forEach((property) => {
            if (item[property].toLowerCase().includes(query.toLowerCase())) {
              containsQuery = true;
            }
          });

          if (!containsQuery) {
            matches = false;
          }
        }
        return matches;
      })
  );

const getExportList = () =>
  createSelector(selectProjects, (projectState) =>
    projectState.get('exportList')
  );

const getProjectStatus = () =>
  createSelector(selectProjects, (projectState) =>
    projectState.get('project_status')
  );
const getUsers = () =>
  createSelector(selectProjects, (userState) => userState.get('users'));
const getSearchQuery = () =>
  createSelector(selectProjects, (userState) => userState.get('key'));
const getKinds = () =>
  createSelector(selectProjects, (kindState) => kindState.get('kinds'));
const getVideoId = () =>
  createSelector(selectProjects, (projectState) => projectState.get('videoId'));
const getTextFileId = () =>
  createSelector(selectProjects, (projectState) =>
    projectState.get('textFileId')
  );
const getfxFileId = () =>
  createSelector(selectProjects, (projectState) =>
    projectState.get('fxFileId')
  );
const getAppImgId = () =>
  createSelector(selectProjects, (projectState) =>
    projectState.get('appImgId')
  );
const getNotifications = () =>
  createSelector(selectProjects, (notificationState) =>
    notificationState.get('notifications')
  );
const getNotificationCount = () =>
  createSelector(selectProjects, (notificationState) =>
    notificationState.get('notificationCount')
  );
const getAcceptSuccess = () =>
  createSelector(selectProjects, (notificationState) =>
    notificationState.get('accept_success')
  );
const getFinFilter = () =>
  createSelector(selectProjects, (projectState) =>
    projectState.get('finFilter')
  );
const getFinancial = () =>
  createSelector(selectProjects, (projectState) =>
    projectState.get('financialProjects')
  );
const getUsersSorted = () =>
  createSelector(getUsers(), (users) => {
    return (
      (users && users.sort((a, b) => (a.firstName > b.firstName ? 1 : -1))) ||
      []
    );
  });
const getProducers = () =>
  createSelector(getUsers(), (users) => {
    const producers =
      users &&
      users.filter(
        (user) => user && user.role && user.role.handle === 'PRODUCER'
      );
    return (
      (producers &&
        producers.sort((a, b) => (a.firstName > b.firstName ? 1 : -1))) ||
      []
    );
  });
const getClients = () =>
  createSelector(getUsers(), (users) => {
    const clients =
      users &&
      users.filter(
        (user) => user && user.role && user.role.handle === 'CLIENT'
      );
    return (
      (clients &&
        clients.sort((a, b) => (a.firstName > b.firstName ? 1 : -1))) ||
      []
    );
  });
const getDirectors = () =>
  createSelector(
    getUsers(),
    (users) =>
      users &&
      users.filter(
        (user) => user && user.role && user.role.handle === 'DIRECTOR'
      )
  );
const getTranslators = () =>
  createSelector(
    getUsers(),
    (users) =>
      users &&
      users.filter(
        (user) => user && user.role && user.role.handle === 'TRANSLATOR'
      )
  );
const getTechMixers = () =>
  createSelector(
    getUsers(),
    (users) =>
      users &&
      users.filter(
        (user) => user && user.role && user.role.handle === 'TECHMIXER'
      )
  );
const getDubbers = () =>
  createSelector(
    getUsers(),
    (users) =>
      users &&
      users.filter(
        (user) => user && user.role && user.role.handle === 'VOICEACTOR'
      )
  );

const PullDubPending = () =>
  createSelector(selectProjects, (projectState) =>
    projectState.get('dubPending')
  );

const getDubPending = () =>
  createSelector(
    PullDubPending(),
    getSearchQuery(),
    (characters, key) =>
      characters &&
      characters.filter((character) => {
        key = key ? key.toLowerCase() : '';
        return key
          ? character &&
              (character.title.toLowerCase().indexOf(key) > -1 ||
                (character.project &&
                  character.project.title.toLowerCase().indexOf(key) > -1))
          : true;
      })
  );

const PullOldProjects = () =>
  createSelector(selectProjects, (projectState) =>
    projectState.get('oldProjects')
  );

const getOldProjects = () =>
  createSelector(
    PullDubPending(),
    getSearchQuery(),
    (characters, key) =>
      characters &&
      characters.filter((character) => {
        key = key ? key.toLowerCase() : '';
        return key
          ? character &&
              (character.title.toLowerCase().indexOf(key) > -1 ||
                (character.project &&
                  character.project.title.toLowerCase().indexOf(key) > -1))
          : true;
      })
  );

const buildObj = (item, indx) => {
  //console.log("item",item);

  const project = {};
  project.title = item.title;
  project.pid = item._id;
  project.kind = item.kind;
  project.minutes = item.minutes;
  project.pages = item.pages;
  project.startDate = moment(item.startDate).format('YYYY-MM-DD');
  project.endDate = moment(item.endDate).format('YYYY-MM-DD');
  project.state = item.state;
  project.totalTakes = item.totalTakes;
  project.clients =
    item.clients &&
    item.clients.map((client) => `${client.firstName} ${client.lastName}`);
  //project.client = `${item.client.firstName} ${item.client.lastName}`;
  //project.clientId = item.client._id;
  project.producer = item.producers[0]
    ? `${item.producers[0].firstName} ${item.producers[0].lastName}`
    : null;
  project.director = item.directors[0]
    ? `${item.directors[0].firstName} ${item.directors[0].lastName}`
    : null;
  project.translator = item.translators[0]
    ? `${item.translators[0].firstName} ${item.translators[0].lastName}`
    : null;
  project.techMixer = item.techMixers[0]
    ? `${item.techMixers[0].firstName} ${item.techMixers[0].lastName}`
    : null;
  project.actor =
    item.characters[indx] && item.characters[indx].inCharge
      ? `${item.characters[indx].inCharge.firstName} ${item.characters[indx].inCharge.lastName}`
      : null;
  project.character = item.characters[indx]
    ? `${item.characters[indx].title}`
    : null;
  project.studioTime =
    item.characters[indx] && item.characters[indx].studioTime
      ? item.characters[indx].studioTime
      : 0;
  project.takes =
    item.characters[indx] && item.characters[indx].takesAndRetakes
      ? `${item.characters[indx].takesAndRetakes.takes}`
      : null;
  project.reTakes =
    item.characters[indx] && item.characters[indx].takesAndRetakes
      ? `${item.characters[indx].takesAndRetakes.retakes}`
      : null;
  project.actorUsername = item.voiceActors[indx]
    ? `${item.voiceActors[indx].username}`
    : null;
  project.actorLevel = item.voiceActors[indx]
    ? `${item.voiceActors[indx].actorLevel}`
    : null;
  project.techUsername = item.techMixers[0]
    ? `${item.techMixers[0].username}`
    : null;
  project.transUsername = item.translators[0]
    ? `${item.translators[0].username}`
    : null;
  project.direcUsername = item.directors[0]
    ? `${item.directors[0].username}`
    : null;
  project.prodUsername = item.producers[0]
    ? `${item.producers[0].username}`
    : null;
  return project;
};
const getFinFiltered = () =>
  createSelector(
    getFinancial(),
    getFinFilter(),
    (financials, filter) =>
      financials &&
      financials.reduce(function (result, current) {
        result = result || [];
        // console.log(current);
        if (current.characters.length > 0) {
          current.characters.map((va, indx) => {
            result.push(buildObj(current, indx));
            return true;
          });
        } else {
          result.push(buildObj(current, 0));
        }
        if (filter) {
          Object.keys(filter).forEach((key) => {
            if (filter[key] === undefined) {
              delete filter[key];
            }
          });
        }

        // console.log(result);
        const filterKeys = filter ? Object.keys(filter) : [];
        const finData =
          result.filter((eachObj) => {
            return filterKeys.every((eachKey) => {
              // console.log(eachKey);
              if (!filter) {
                return true; // passing an empty filter means that filter is ignored.
              } else {
                if (eachKey === 'kind') {
                  return filter.kind.indexOf(eachObj.kind) > -1;
                } else if (eachKey === 'startDate' || eachKey === 'endDate') {
                  // console.log(eachObj, filter);
                  return (
                    moment(eachObj.startDate).isSameOrAfter(filter.startDate) &&
                    moment(eachObj.endDate).isSameOrBefore(filter.endDate)
                  );
                } else if (eachKey === 'username') {
                  // console.log(filter[eachKey], eachObj.actorUsername)
                  return (
                    filter[eachKey] === eachObj.actorUsername ||
                    filter[eachKey] === eachObj.techUsername ||
                    filter[eachKey] === eachObj.transUsername ||
                    filter[eachKey] === eachObj.direcUsername ||
                    filter[eachKey] === eachObj.prodUsername
                  );
                } else if (eachKey === 'category') {
                  return filter.category.indexOf(eachObj.actorLevel) > -1;
                }
                // else if(eachKey === 'role'){
                //   if(filter.role == 'TRANSLATOR'){
                //     return eachObj.transUsername ? true : false;
                //   }
                // }
                else {
                  return filter[eachKey].includes(eachObj[eachKey]);
                }
              }
            });
          }) || [];
        return finData;
      }, false)
  );

const getFinProjects = () =>
  createSelector(getFinancial(), (financials) => {
    const fin =
      financials &&
      financials.map((fin) => {
        return { pid: fin._id, title: fin.title };
      });
    return (fin && fin.sort((a, b) => (a.title > b.title ? 1 : -1))) || [];
  });

const getUploadProgress = () =>
  createSelector(selectProjects, (projectState) =>
    projectState.get('fileProgress')
  );

export {
  getProjects,
  getDubPending,
  getOldProjects,
  getKinds,
  getUsers,
  getUsersSorted,
  getVideoId,
  getTextFileId,
  getfxFileId,
  getAppImgId,
  getNotifications,
  getNotificationCount,
  getProjectStatus,
  getAcceptSuccess,
  getProducers,
  getClients,
  getDirectors,
  getTranslators,
  getTechMixers,
  getDubbers,
  getExportList,
  getDrawer,
  getCollapsed,
  getProjectsFiltered,
  getText,
  getFinancial,
  getFinFiltered,
  getFinProjects,
  getUploadProgress
};
