const actions = {
  LIST_PROJECTS: 'LIST_PROJECTS',
  LIST_PROJECTS_SUCCESS: 'LIST_PROJECTS_SUCCESS',
  LIST_PROJECTS_KINDS: 'LIST_PROJECTS_KINDS',
  LIST_PROJECTS_KINDS_SUCCESS: 'LIST_PROJECTS_KINDS_SUCCESS',
  LIST_USERS: 'LIST_USERS',
  LIST_USERS_SUCCESS: 'LIST_USERS_SUCCESS',
  CREATE_PROJECTS: 'CREATE_PROJECTS',
  UPDATE_PROJECT: 'UPDATE_PROJECT',
  UPDATE_PROJECT_SUCCESS: 'UPDATE_PROJECT_SUCCESS',
  UPDATE_PROJECT_STATE: 'UPDATE_PROJECT_STATE',
  DELETE_PROJECT: 'DELETE_PROJECT',
  DELETE_PROJECT_SUCCESS: 'DELETE_PROJECT_SUCCESS',
  LIST_NOTIFICATIONS: 'LIST_NOTIFICATIONS',
  LIST_NOTIFICATIONS_SUCCESS: 'LIST_NOTIFICATIONS_SUCCESS',
  ACCEPT_NOTIFICATION: 'ACCEPT_NOTIFICATION',
  REJECT_NOTIFICATION: 'REJECT_NOTIFICATION',
  READ_NOTIFICATION: 'READ_NOTIFICATION',
  ADD_CASTING: 'ADD_CASTING',
  DELETE_CASTING: 'DELETE_CASTING',
  FETCH_CASTING: 'FETCH_CASTING',
  ADD_FILE: 'ADD_FILE',
  ADD_FILE_PROJECT: 'ADD_FILE_PROJECT',
  SIGNED_URL_SUCCESS: 'SIGNED_URL_SUCCESS',
  SET_LANG: 'SET_LANG',
  PROJECT_FEEDBACK: 'PROJECT_FEEDBACK',
  PENDING_EXPORT: 'PENDING_EXPORT',
  PENDING_EXPORT_SUCCESS: 'PENDING_EXPORT_SUCCESS',
  PENDING_DUB: 'PENDING_DUB',
  PENDING_DUB_SUCCESS: 'PENDING_DUB_SUCCESS',
  OLD_PROJECTS: 'OLD_PROJECTS',
  OLD_PROJECTS_SUCCESS: 'OLD_PROJECTS_SUCCESS',
  INVI_PENDING: 'INVI_PENDING',
  INVI_PENDING_SUCCESS: 'INVI_PENDING_SUCCESS',
  SET_KEY: 'SET_KEY',
  CLEAR_ALL_NOTIFICATIONS: 'CLEAR_ALL_NOTIFICATIONS',
  CHANGE_USER: 'CHANGE_USER',
  SET_FILTER: 'SET_FILTER',
  SET_TEXT: 'SET_TEXT',
  HANDLE_TEXT: 'HANDLE_TEXT',
  UPDATE_TEXT: 'UPDATE_TEXT',
  UPDATE_CHARACTER: 'UPDATE_CHARACTER',
  LIST_FINANCIAL: 'LIST_FINANCIAL',
  LIST_FINANCIAL_SUCCESS: 'LIST_FINANCIAL_SUCCESS',
  SET_FIN_FILTER: 'SET_FIN_FILTER',
  RESET: 'RESET',
  reset: () => ({
    type: actions.RESET
  }),
  listProjects: (payload) => ({
    type: actions.LIST_PROJECTS,
    payload: payload
  }),
  addCasting: (payload, projectId) => ({
    type: actions.ADD_CASTING,
    payload,
    projectId
  }),
  deleteCasting: (projectId, characterId) => ({
    type: actions.DELETE_CASTING,
    projectId,
    characterId
  }),
  addFile: (file, fileType) => {
    return {
      type: actions.ADD_FILE,
      file,
      fileType
    };
  },
  addFileToProject: (file, fileType, projectId) => {
    return {
      type: actions.ADD_FILE_PROJECT,
      file,
      fileType,
      projectId
    };
  },
  listNotifications: () => ({
    type: actions.LIST_NOTIFICATIONS
  }),
  listProjectKinds: (payload) => ({
    type: actions.LIST_PROJECTS_KINDS
  }),
  listUsers: (payload) => ({
    type: actions.LIST_USERS,
    payload
  }),
  createProject: (payload, spinHandler) => ({
    type: actions.CREATE_PROJECTS,
    payload,
    spinHandler
  }),
  editProject: (payload, pId, kind, spinHandler) => ({
    type: actions.UPDATE_PROJECT,
    payload,
    pId,
    kind,
    spinHandler
  }),
  deleteProject: (projectId, spinHandler) => ({
    type: actions.DELETE_PROJECT,
    projectId,
    spinHandler
  }),
  deleteProjectSuccess: (projectId) => ({
    type: actions.DELETE_PROJECT_SUCCESS,
    projectId
  }),
  projectFeedback: (payload) => ({
    type: actions.PROJECT_FEEDBACK,
    payload: payload
  }),
  readNotification: (payload) => ({
    type: actions.READ_NOTIFICATION,
    payload: payload
  }),
  setLang: (langs) => ({
    type: actions.SET_LANG,
    langs
  }),
  pendingExport: () => ({
    type: actions.PENDING_EXPORT
  }),
  pendingDub: (payload) => ({
    type: actions.PENDING_DUB,
    payload
  }),
  oldProjects: (payload) => ({
    type: actions.OLD_PROJECTS,
    payload
  }),
  inviPending: () => ({
    type: actions.INVI_PENDING
  }),
  updateSearchKey: (key) => ({
    type: actions.SET_KEY,
    key
  }),
  clearAllNotifications: () => ({
    type: actions.CLEAR_ALL_NOTIFICATIONS
  }),
  updateState: (pId, payload, cb) => ({
    type: actions.UPDATE_PROJECT_STATE,
    pId,
    payload,
    cb
  }),
  changeUser: (payload, pId, cb) => ({
    type: actions.CHANGE_USER,
    payload,
    pId,
    cb
  }),
  setFilter: (key) => ({
    type: actions.SET_FILTER,
    key
  }),
  setTransText: (text) => ({
    type: actions.SET_TEXT,
    text
  }),
  updateText: (text, textType, key) => ({
    type: actions.UPDATE_TEXT,
    text,
    textType,
    key
  }),
  updateCharacter: (character, key) => ({
    type: actions.UPDATE_CHARACTER,
    character,
    key
  }),
  handleText: (task, key) => ({
    type: actions.HANDLE_TEXT,
    task,
    key
  }),
  listFinancial: () => ({
    type: actions.LIST_FINANCIAL
  }),
  setFinFilter: (filter) => ({
    type: actions.SET_FIN_FILTER,
    filter
  })
};
export const ActionTypes = {
  UPLOAD_REQUEST: 'UPLOAD_REQUEST',
  UPLOAD_PROGRESS: 'UPLOAD_PROGRESS',
  UPLOAD_SUCCESS: 'UPLOAD_SUCCESS',
  UPLOAD_FAILURE: 'UPLOAD_FAILURE'
};
export const uploadRequest = (file, fileType, projectId, spinHandler) => ({
  type: ActionTypes.UPLOAD_REQUEST,
  file,
  fileType,
  projectId,
  spinHandler
});
export const uploadProgress = (file, progress) => ({
  type: ActionTypes.UPLOAD_PROGRESS,
  payload: { progress, id: file.size },
  meta: { file }
});
export const uploadSuccess = (file) => ({
  type: ActionTypes.UPLOAD_SUCCESS,
  payload: { id: file.size },
  meta: { file }
});
export const uploadFailure = (file, err) => ({
  type: ActionTypes.UPLOAD_FAILURE,
  payload: { err, id: file.size },
  meta: { file }
});
export default actions;
