const actions = {
  GET_PROJECT_INFO: 'GET_PROJECT_INFO',
  GET_PROJECT_INFO_SUCCESS: 'GET_PROJECT_INFO_SUCCESS',
  GET_ASSET: 'GET_ASSET',
  GET_ASSET_SUCCESS: 'GET_ASSET_SUCCESS',
  GET_ASSET_FX_SUCCESS: 'GET_ASSET_FX_SUCCESS',
  SET_FX: 'SET_FX',
  GET_SUBTITLES: 'GET_SUBTITLES',
  GET_SUBTITLES_SUCCESS: 'GET_SUBTITLES_SUCCESS',
  GET_CHARS_SUCCESS: 'GET_CHARS_SUCCESS',
  SUBMIT_FILE: 'SUBMIT_FILE',
  SUBMIT_RETAKE: 'SUBMIT_RETAKE',
  GET_RECORDINGS: 'GET_RECORDINGS',
  GET_RECORDING_SUCCESS: 'GET_RECORDING_SUCCESS',
  EXPORT_PROJECT: 'EXPORT_PROJECT',
  SAVE_RECORDING: 'SAVE_RECORDING',
  FETCH_RECORDINGS_SUCCESS: 'FETCH_RECORDINGS_SUCCESS',
  GET_SAVED_TRACKS: 'GET_SAVED_TRACKS',
  SAVED_TRACKS: 'SAVED_TRACKS',
  QA_UPDATE_RECORDING: 'QA_UPDATE_RECORDING',
  REPLACE_RECORDING: 'REPLACE_RECORDING',
  MAKE_LIVE: 'MAKE_LIVE',
  BATCH_RETAKE: 'BATCH_RETAKE',

  fetchProjectInfo: (pId, cId) => ({
    type: actions.GET_PROJECT_INFO,
    pId,
    cId
  }),

  getSavedDubTracks: (pId, cId) => ({
    type: actions.GET_SAVED_TRACKS,
    pId,
    cId
  }),

  getAsset: (payload) => ({
    type: actions.GET_ASSET,
    payload
  }),

  getSubTitles: (file) => ({
    type: actions.GET_SUBTITLES,
    file
  }),

  submitFile: (fileArr, wavArr, data, takeLength, cb) => ({
    type: actions.SUBMIT_FILE,
    fileArr,
    wavArr,
    data,
    takeLength,
    cb
  }),

  submitRetake: (fileArr, wavArr, data, retakeInfo, cb) => ({
    type: actions.SUBMIT_RETAKE,
    fileArr,
    wavArr,
    data,
    retakeInfo,
    cb
  }),

  getRecordings: (projectId, frameRate, cb, expList) => ({
    type: actions.GET_RECORDINGS,
    projectId,
    frameRate,
    cb
  }),

  exportProject: (pId, data, asset, takes, cb) => ({
    type: actions.EXPORT_PROJECT,
    pId,
    data,
    asset,
    takes,
    cb
  }),

  saveRecording: (fileArr, wavArr, data, takeLength, cb) => ({
    type: actions.SAVE_RECORDING,
    fileArr,
    wavArr,
    data,
    takeLength,
    cb
  }),

  fetchRecordingsSuccess: (recordings) => ({
    type: actions.FETCH_RECORDINGS_SUCCESS,
    recordings
  }),

  qaUpdateRecording: (recordings, projectId, takeNo, cb) => ({
    type: actions.QA_UPDATE_RECORDING,
    recordings,
    projectId,
    takeNo,
    cb
  }),

  replaceRecording: (assetId, file) => ({
    type: actions.REPLACE_RECORDING,
    assetId,
    file
  }),
  makeLive: (pid) => ({
    type: actions.MAKE_LIVE,
    pid
  }),
  batchRetake: (payload, pId) => ({
    type: actions.BATCH_RETAKE,
    payload,
    pId
  })
};
export default actions;
