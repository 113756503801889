import { Map } from 'immutable';
import { getDefaultPath } from 'src/utils/urlSync';
import actions, { getView } from './actions';

const preKeys = getDefaultPath();

const initState = new Map({
  collapsed: window.innerWidth > 1220 ? false : true,
  view: getView(window.innerWidth),
  height: window.innerHeight,
  openDrawer: false,
  openKeys: preKeys,
  current: preKeys,
  chatBoxOpen: false,
});
export default function appReducer(state = initState, action) {
  switch (action.type) {
    case actions.COLLAPSE_NOW:
      return state.set('collapsed', true);
    case actions.COLLPSE_CHANGE:
      return state.set('collapsed', !state.get('collapsed'));
    case actions.COLLPSE_OPEN_DRAWER:
      return state.set('openDrawer', !state.get('openDrawer'));
    case actions.TOGGLE_ALL:
      if (state.get('view') !== action.view || action.height !== state.height) {
        const height = action.height ? action.height : state.height;
        return state
          .set('collapsed', action.collapsed)
          .set('view', action.view)
          .set('height', height);
      }
      break;
    case actions.CHANGE_OPEN_KEYS:
      return state.set('openKeys', action.openKeys);
    case actions.CHANGE_CURRENT:
      return state.set('current', action.current);
    case actions.LOGOUT:
      return state.set('current', []);
    case actions.CHAT_STATUS:
      return state.set('chatBoxOpen', action.active);
    default:
      return state;
  }
  return state;
}
