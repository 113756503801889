export const CLIENT = 'CLIENT';
export const MIXER = 'TECHMIXER';
export const TRANSLATOR = 'TRANSLATOR';
export const ACTOR = 'VOICEACTOR';
export const DIRECTOR = 'DIRECTOR';
export const PRODUCER = 'PRODUCER';
export const MANAGER = 'MANAGER';
export const ADMIN = 'ADMIN';

export const mapLevel = (key) => {
  const levels = {
    BASIC: 'Basic',
    PRO: 'Pro',
    ADVANCED: 'Advanced',
    CACHE: 'Cache'
  };
  return levels[key];
};

export const isActor = (role) => role && role.handle === ACTOR;
export const isTecnico = (role) => role && role.handle === MIXER;
export const isDirector = (role) => role && role.handle === DIRECTOR;
export const isProducer = (role) => role && role.handle === PRODUCER;
export const isClient = (role) => role && role.handle === CLIENT;
export const isManager = (role) => role && role.handle === MANAGER;
export const isTraductor = (role) => role && role.handle === TRANSLATOR;

export const getChiefName = (role) => {
  if (role === MIXER) return 'Jefe de técnicos';
  if (role === PRODUCER) return 'Jefe de Producción';
  if (role === DIRECTOR) return 'Jefe de Dirección';
  return `Jefe de ${role}`;
};
