import React from 'react';
import RefreshIcon from '@material-ui/icons/Refresh';
import {
  Briefcase as BriefcaseIcon,
  Folder as FolderIcon,
  UserPlus as UserPlusIcon,
  Edit as EditIcon,
  PieChart as PieChartIcon,
  Users as UsersIcon,
  UserCheck as ClientsIcon,
  FileText,
  Mic as MicIcon,
  Inbox as InboxIcon,
  CheckSquare as CheckSquareIcon,
  Film as FilmIcon,
  DollarSign as DolarSingIcon,
  Download as DownloadIcon,
  FileText as FileTextIcon
} from 'react-feather';

import { Chip } from '@material-ui/core';

const menu = {
  ADMIN: [
    {
      subheader: 'Reports',
      items: [
        {
          title: 'Dashboard',
          icon: PieChartIcon,
          href: '/',
          info: () => <Chip color="secondary" size="small" label="Pte" />
        }
      ]
    },
    {
      subheader: 'sidebar.projects',
      items: [
        {
          key: 'projects',
          href: '/projects',
          title: 'sidebar.projects',
          icon: DownloadIcon
        },
        {
          key: 'users',
          href: '/users',
          title: 'sidebar.users',
          icon: UsersIcon
        }
      ]
    }
  ],
  MANAGER: [
    {
      subheader: 'Reports',
      items: [
        {
          title: 'Dashboard',
          icon: PieChartIcon,
          href: '/',
          info: () => <Chip color="secondary" size="small" label="Pte" />
        }
      ]
    },
    {
      subheader: 'sidebar.admin',
      items: [
        {
          key: 'clients',
          href: '/clients',
          title: 'sidebar.clients',
          icon: ClientsIcon
        },
        {
          key: 'prices',
          href: '/projects/prices',
          title: 'sidebar.prices',
          icon: DolarSingIcon
        },
        {
          title: 'sidebar.users',
          icon: UsersIcon,
          href: '/users'
        }
      ]
    },
    {
      subheader: 'sidebar.projects',
      items: [
        {
          key: 'activeProjects',
          href: '/projects/activeProjects',
          title: 'sidebar.ActiveProjects',
          icon: DownloadIcon
        },
        {
          key: 'financialList',
          href: '/projects/financialList',
          title: 'page.financial',
          icon: FileTextIcon
        },
        {
          key: 'oldProjects',
          href: '/projects/oldProjects',
          title: 'sidebar.oldProjects',
          icon: FolderIcon
        }
      ]
    }
  ],
  PRODUCER: [
    {
      subheader: 'Reports',
      items: [
        {
          title: 'Dashboard',
          icon: PieChartIcon,
          href: '/',
          info: () => <Chip color="secondary" size="small" label="Pte" />
        }
      ]
    },
    {
      subheader: 'sidebar.projects',
      items: [
        {
          key: 'activationPending',
          href: '/projects/activationPending',
          title: 'sidebar.ActivationPending',
          icon: CheckSquareIcon
        },
        {
          key: 'castAssign',
          href: '/projects/castAssignPending',
          title: 'sidebar.CastAssignPending',
          icon: UserPlusIcon
        },
        {
          key: 'cast-review-Pending',
          href: '/projects/castReviewPending',
          title: 'sidebar.CastReviewPending',
          icon: UsersIcon
        },
        {
          key: 'activeProjects',
          href: '/projects/activeProjects',
          title: 'sidebar.ActiveProjects',
          icon: DownloadIcon
        },
        {
          key: 'oldProjects',
          href: '/projects/oldProjects',
          title: 'sidebar.oldProjects',
          icon: FolderIcon
        }
      ]
    }
  ],
  TRANSLATOR: [
    {
      subheader: 'Reports',
      items: [
        {
          title: 'Dashboard',
          icon: PieChartIcon,
          href: '/',
          info: () => <Chip color="secondary" size="small" label="Pte" />
        }
      ]
    },
    {
      subheader: 'sidebar.projects',
      items: [
        {
          key: 'translationPending',
          href: '/projects/translationPending',
          title: 'sidebar.TranslationPending',
          icon: FilmIcon
        },
        {
          key: 'oldProjects',
          href: '/projects/oldProjects',
          title: 'sidebar.oldProjects',
          icon: FolderIcon
        }
      ]
    }
  ],
  TECHMIXER: [
    {
      subheader: 'Reports',
      items: [
        {
          title: 'Dashboard',
          icon: PieChartIcon,
          href: '/',
          info: () => <Chip color="secondary" size="small" label="Pte" />
        }
      ]
    },
    {
      subheader: 'sidebar.projects',
      items: [
        {
          key: 'pendingTaking',
          href: '/projects/pendingTaking',
          title: 'sidebar.PendingTaking',
          icon: FilmIcon
        },
        {
          key: 'castingPending',
          href: '/projects/castingPending',
          title: 'sidebar.CastingPending',
          icon: UsersIcon
        },
        {
          key: 'cast-review-Pending',
          href: '/projects/castReviewPending',
          title: 'sidebar.CastReviewPending',
          icon: UsersIcon
        },
        {
          key: 'qaPending',
          href: '/projects/qaPending',
          title: 'sidebar.QaPend',
          icon: EditIcon
        } /*
        {
          key: 'exportPending',
          href: '/projects/exportPending',
          title: 'sidebar.ExportPending',
          icon: BriefcaseIcon
        },*/,
        {
          key: 'oldProjects',
          href: '/projects/oldProjects',
          title: 'sidebar.oldProjects',
          icon: FolderIcon
        }
      ]
    }
  ],
  DIRECTOR: [
    {
      subheader: 'Reports',
      items: [
        {
          title: 'Dashboard',
          icon: PieChartIcon,
          href: '/',
          info: () => <Chip color="secondary" size="small" label="Pte" />
        }
      ]
    },
    {
      subheader: 'sidebar.projects',
      items: [
        {
          key: 'textReviewPending',
          href: '/projects/textReviewPending',
          title: 'sidebar.TxtReviewPending',
          icon: FileText
        },
        {
          key: 'castingPending',
          href: '/projects/castingPending',
          title: 'sidebar.CastingPending',
          icon: UsersIcon
        },
        {
          key: 'qaPending',
          href: '/projects/qaPending',
          title: 'sidebar.QaPending',
          icon: EditIcon
        },
        {
          key: 'oldProjects',
          href: '/projects/oldProjects',
          title: 'sidebar.oldProjects',
          icon: FolderIcon
        }
      ]
    }
  ],
  VOICEACTOR: [
    {
      subheader: 'Reports',
      items: [
        {
          title: 'Dashboard',
          icon: PieChartIcon,
          href: '/',
          info: () => <Chip color="secondary" size="small" label="Pte" />
        }
      ]
    },
    {
      subheader: 'sidebar.projects',
      items: [
        {
          key: 'dubPending',
          href: '/projects/dubPending',
          title: 'sidebar.DubPending',
          icon: MicIcon
        },
        {
          key: 'invitationPending',
          href: '/projects/invitationPending',
          title: 'sidebar.InvitationPending',
          icon: FileText
        },
        // {
        //   key: 'retakes',
        //   href: '/projects/retakes',
        //   title: 'sidebar.retakes',
        //   icon: RefreshIcon,
        //   info: () => <Chip color="secondary" size="small" label="Pte" />
        // },
        {
          key: 'oldProjects',
          href: '/projects/oldProjects',
          title: 'sidebar.oldProjects',
          icon: FolderIcon
        }
      ]
    }
  ],
  CLIENT: [
    {
      subheader: 'Reports',
      items: [
        {
          title: 'Dashboard',
          icon: PieChartIcon,
          href: '/',
          info: () => <Chip color="secondary" size="small" label="Pte" />
        }
      ]
    },
    {
      subheader: 'sidebar.projects',
      items: [
        {
          key: 'activeProjects',
          href: '/projects/activeProjects',
          title: 'sidebar.ActiveProjects',
          icon: InboxIcon
        },
        {
          key: 'castPending',
          href: '/projects/castPending',
          title: 'sidebar.CastReviewPending',
          icon: UsersIcon
        },
        {
          key: 'qaPending',
          href: '/projects/qaPending',
          title: 'sidebar.supervisionPening',
          icon: EditIcon
        },
        {
          key: 'oldProjects',
          href: '/projects/oldProjects',
          title: 'sidebar.oldProjects',
          icon: FolderIcon
        }
      ]
    }
  ]
};

export default menu;
