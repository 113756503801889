import { fromJS } from 'immutable';
import actions, { ActionTypes } from './actions';

const initState = fromJS({
  projects: null,
  kinds: null,
  users: null,
  notifications: null,
  notificationCount: 0,
  project_status: null,
  accept_success: null,
  videoId: null,
  textFileId: null,
  fxFileId: null,
  appImgId: null,
  langs: null,
  exportList: null,
  key: null,
  dubPending: null,
  filter: null,
  text: [],
  financialProjects: null,
  finFilter: null,
  fileProgress: null
});

const textObj = {
  timeframe: 0,
  originalText: '',
  originalComment: '',
  text: '',
  comment: '',
  seekPoint: null
};

export default function ProjectReducer(state = initState, action) {
  switch (action.type) {
    case actions.LIST_PROJECTS_SUCCESS:
      return state.set('projects', action.projects);
    case actions.DELETE_PROJECT_SUCCESS:
      const newProjects = state
        .get('projects')
        .filter((project) => project._id !== action.projectId);
      return state.set('projects', newProjects);
    case actions.LIST_PROJECTS_KINDS_SUCCESS:
      return state.set('kinds', action.kinds);
    case actions.LIST_USERS_SUCCESS:
      return state.set('users', action.users);
    case actions.SIGNED_URL_SUCCESS:
      if (action.fileType === 'video') {
        return state.set('videoId', action.id);
      } else if (action.fileType === 'text') {
        return state.set('textFileId', action.id);
      } else if (action.fileType === 'fx') {
        return state.set('fxFileId', action.id);
      } else if (action.fileType === 'appImg') {
        return state.set('appImgId', action.id);
      }
      break;
    case actions.LIST_NOTIFICATIONS_SUCCESS:
      return state
        .set('notifications', action.notifications)
        .set('notificationCount', action.totalCount);
    case actions.ACCEPT_SUCCESS:
      return state.set('accept_success', true);
    case actions.PENDING_EXPORT_SUCCESS:
      return state.set('exportList', action.projects);
    case actions.OLD_PROJECTS_SUCCESS:
      return state.set('dubPending', action.projects);
    case actions.PENDING_DUB_SUCCESS:
      return state.set('dubPending', action.projects);
    case actions.INVI_PENDING_SUCCESS:
      return state.set('dubPending', action.projects);
    case actions.SET_KEY:
      return state.set('key', action.key);
    case actions.SET_LANG:
      return state.set('langs', action.langs);
    case actions.SET_FILTER:
      return state.set('filter', action.key);
    case actions.SET_TEXT:
      return state.set('text', action.text);
    case actions.UPDATE_TEXT:
      let text = Object.assign([], state.get('text'));
      // text = Array.isArray(text) ? text : text.toArray();
      if (action.textType === 'text') {
        text[action.key].text = action.text;
      } else if (action.textType === 'comment') {
        text[action.key].comment = action.text;
      } else if (action.textType === 'character') {
        text[action.key].character = action.text;
      }
      return state.set('text', text);
    // const newData = { text: action.text };
    // return state
    //   .setIn(['text', action.key, action.textType], action.text);

    case actions.HANDLE_TEXT:
      let allText = Object.assign([], state.get('text'));
      if (action.task === 'ADD') {
        allText.splice(action.key + 1, 0, Object.assign({}, textObj));
      } else {
        allText.splice(action.key, 1);
      }
      return state.set('text', allText);
    case actions.LIST_FINANCIAL_SUCCESS:
      return state.set('financialProjects', action.financialProjects);
    case actions.SET_FIN_FILTER:
      return state.set('finFilter', action.filter);
    case ActionTypes.UPLOAD_PROGRESS:
      return state.set('fileProgress', {
        ...state.get('fileProgress'),
        [action.payload.id]: {
          //...state.get('fileProgress')[action.payload.id],
          progress: action.payload.progress
        }
      });
    /*case ActionTypes.UPLOAD_SUCCESS:
      return state.set('fileProgress', {
        ...state.get('fileProgress'),
        [action.payload.id]: {
          ...state.get('fileProgress')[action.payload.id],
          success: true
        }
      });
    case ActionTypes.UPLOAD_FAILURE:
      return state.set('fileProgress', {
        ...state.get('fileProgress'),
        [action.payload.id]: {
          ...state.get('fileProgress')[action.payload.id],
          success: false,
          progress: 0,
          error: action.payload.err
        }
      });*/
    case actions.RESET:
      return initState;
    default:
      return state;
  }
}
