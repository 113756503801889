/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, matchPath } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import IntlMessages from 'src/utils/intlMessages';

import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles
} from '@material-ui/core';
import Logo from 'src/components/common/Logo';
import { getProfile } from 'src/redux/auth/selectors';
import { siteConfig } from '../../../config';
import packageJson from '../../../../package.json';

import NavItem from './NavItem';
import menu from './Items';
import getInitials from 'src/utils/getInitials';
import { getChiefName } from 'src/utils/userRoles';

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  footer: {
    bottom: 0
  }
}));

const NavBar = ({ onMobileClose, openMobile, isNavOpen }) => {
  const classes = useStyles();
  const location = useLocation();
  const user = useSelector(getProfile());

  const sections = user.role ? menu[user.role.handle] : menu['MANAGER'];

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box display="flex" justifyContent="center">
            <RouterLink to={`/profile/${user._id}`}>
              <Avatar alt="User" className={classes.avatar} src={user.avatar}>
                {getInitials(`${user.firstName} ${user.lastName}`)}
              </Avatar>
            </RouterLink>
          </Box>
          <Box mt={2} textAlign="center">
            <Link
              component={RouterLink}
              to={`/profile/${user._id}`}
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {user.firstName} {user.lastName}
            </Link>
            <Typography variant="body2" color="textSecondary">
              <IntlMessages id="profile.role" />
              <Link component={RouterLink} to={`/profile/${user._id}`}>
                {user.isChief ? (
                  getChiefName(user.role.handle)
                ) : (
                  <IntlMessages id={user.role.handle} />
                )}
              </Link>
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {sections.map((section) => (
            <List
              key={section.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  <IntlMessages id={section.subheader} />
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname
              })}
            </List>
          ))}
        </Box>
        <Divider />
        <Box p={2}>
          <Box
            p={2}
            textAlign="center"
            borderRadius="borderRadius"
            bgcolor="background.dark"
          >
            <Typography variant="h6" color="textPrimary">
              ¿ Necesitas ayuda ?
            </Typography>
            <Link
              variant="subtitle1"
              color="secondary"
              component={RouterLink}
              to="/docs"
            >
              Mira nuestros videotutoriales
            </Link>
          </Box>
        </Box>
      </PerfectScrollbar>
      <div className={classes.footer}>
        <Divider />
        <Box p={2} textAlign="center">
          <div>
            <Typography variant="caption" color="textSecondary">
              {siteConfig.footerText}
            </Typography>
          </div>
          <div>
            <Typography variant="caption" color="textSecondary">
              v{packageJson.version}
            </Typography>
          </div>
          <div>
            <Typography variant="caption" color="textSecondary">
              {packageJson.build_date}
            </Typography>
          </div>
        </Box>
      </div>
    </Box>
  );

  return (
    <>
      {isNavOpen ? (
        <>
          <Hidden lgUp>
            <Drawer
              anchor="left"
              classes={{ paper: classes.mobileDrawer }}
              onClose={onMobileClose}
              open={openMobile}
              variant="temporary"
            >
              {content}
            </Drawer>
          </Hidden>
          <Hidden mdDown>
            <Drawer
              anchor="left"
              classes={{ paper: classes.desktopDrawer }}
              open
              variant="persistent"
            >
              {content}
            </Drawer>
          </Hidden>
        </>
      ) : (
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      )}
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
