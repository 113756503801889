import { fromJS } from 'immutable';
import actions from './actions';

const initState = fromJS({
  default: false,
  contacts: [],
  chatsHistory: [],
  uploadedAudio: {},
  readStatus: {},
  channelId: null,
  channels: [],
  chatLoader: false
});
export default function contactReducer(state = initState, action) {
  switch (action.type) {
    case actions.GET_CONTACTS_SUCCESS:
      return state.set('contacts', action.data.data);
    case actions.CLEAR_CHAT:
      return state.set('chatsHistory', []).set('channelId', null);
    case actions.GET_CHATS_SUCCESS:
      return state.set('chatsHistory', action.data);
    case actions.UPLOAD_AUDIO:
      return state.set('chatLoader', true);
    case actions.UPLOAD_AUDIO_SUCCESS:
      const data = state.toJS().chatsHistory.slice();
      data.push(action.data);
      return state
        .set('chatsHistory', [])
        .set('chatsHistory', data)
        .set('chatLoader', false);
    case actions.CHANGE_STATUS:
      return state.set('readStatus', action.readStatus);
    case actions.SET_CHANEL_ID:
      return state.set('channelId', action.id);
    case actions.GET_CHANNELS_SUCCESS:
      return state.set('channels', action.data.data);
    default:
      return state;
  }
}
