import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { connect } from 'react-redux';

import PublicLayout from './components/layouts/PublicLayout';
import asyncComponent from 'src/utils/AsyncFunc';
import Login from 'src/components/containers/Auth/Login';

const RestrictedRoute = ({ component: Component, isLoggedIn, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isLoggedIn ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location }
          }}
        />
      )
    }
  />
);
const PublicRoutes = ({ history, isLoggedIn }) => {
  return (
    <ConnectedRouter history={history} isLoggedIn={isLoggedIn}>
      <Switch>
        <Route exact path={'/'} component={Login} />
        <Route
          exact
          path={'/404'}
          component={asyncComponent(() =>
            import('src/components/views/errors/NotFoundView')
          )}
        />
        <Route
          exact
          path={'/password/set/:token/:type'}
          component={asyncComponent(() =>
            import('src/components/containers/Auth/PasswordLanding')
          )}
        />
        <Route
          exact
          path={'/login'}
          component={asyncComponent(() =>
            import('src/components/containers/Auth/Login')
          )}
        />
        <Route
          exact
          path={'/password-recovery'}
          component={asyncComponent(() =>
            import('src/components/containers/Auth/RecoveryPassword')
          )}
        />
        <RestrictedRoute
          path="/"
          component={PublicLayout}
          isLoggedIn={isLoggedIn}
        />
      </Switch>
    </ConnectedRouter>
  );
};

export default connect((state) => ({
  isLoggedIn: state.Auth.get('idToken') !== null
}))(PublicRoutes);
