import { all, call, takeEvery, put, fork } from 'redux-saga/effects';
import actions from './actions';
import API from 'src/utils/API';
import Notification from 'src/_old/components/notification';

export function* listAllUsers() {
  yield takeEvery(actions.LIST_ALL_USERS, function* (action) {
    try {
      let payload = {};
      payload.isActive = true;
      const users = yield call(API.users.list, payload);
      yield put({
        type: actions.LIST_ALL_USERS_SUCCESS,
        users
      });
    } catch (err) {
      Notification('error', 'Invalid request', err.error);
    }
  });
}

export function* listRoles() {
  yield takeEvery(actions.LIST_ROLES, function* (action) {
    try {
      const roles = yield call(API.users.getRoles);
      if (roles) {
        yield put({
          type: actions.LIST_USER_ROLES_SUCCESS,
          roles
        });
      }
    } catch (err) {
      Notification('error', 'Invalid request', err.error);
    }
  });
}

export function* listUsersByRole() {
  yield takeEvery(actions.LIST_USERS_BY_ROLE, function* (action) {
    try {
      const roles = yield call(API.users.getRoles);
      if (roles) {
        yield put({
          type: actions.LIST_USER_ROLES_SUCCESS,
          roles
        });
      }
      const role = roles.filter((role) => action.role === role.handle);
      const payload = {
        role: role[0]._id,
        isActive: true
      };
      const users = yield call(API.users.list, payload);
      yield put({
        type: actions.LIST_USERS_BY_ROLE_SUCCESS,
        users
      });
    } catch (err) {
      Notification('error', 'Invalid request', err.error);
    }
  });
}

export function* fetchUser() {
  yield takeEvery(actions.FETCH_USER, function* (action) {
    try {
      const user = yield call(API.users.details, action.userId);
      yield put({
        type: actions.LIST_USER_SUCCESS,
        user
      });
    } catch (err) {
      Notification('error', 'Invalid request', err.error);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(listUsersByRole),
    fork(listRoles),
    fork(listAllUsers),
    fork(fetchUser)
  ]);
}
