/**
 * Auth selectors
 */

import { createSelector } from 'reselect';

const selectAuth = (state) => state.Auth;

const getProfile = () =>
  createSelector(selectAuth, (profileState) => profileState.get('profile'));
const getToken = () =>
  createSelector(selectAuth, (tokenState) => tokenState.get('token'));

export { getProfile, getToken };
