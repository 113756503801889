const actions = {
  GET_CONTACTS: "GET_CONTACTS",
  GET_CONTACTS_SUCCESS: "GET_CONTACTS_SUCCESS",
  UPLOAD_AUDIO: "UPLOAD_AUDIO",
  UPLOAD_AUDIO_SUCCESS: "UPLOAD_AUDIO_SUCCESS",
  GET_CHATS: "GET_CHATS",
  GET_CHATS_SUCCESS: "GET_CHATS_SUCCESS",
  CLEAR_CHAT: "CLEAR_CHAT",
  CHANGE_STATUS: "CHANGE_STATUS",
  READ_MESSAGES: "READ_MESSAGES",
  GET_CHANNEL: "GET_CHANNEL",
  SET_CHANEL_ID: "SET_CHANEL_ID",
  GET_CHANNELS: "GET_CHANNELS",
  GET_CHANNELS_SUCCESS: "GET_CHANNELS_SUCCESS",
  CLEAR_CHANNEL: "CLEAR_CHANNEL",
  NOTIFY_MESSAGE: "NOTIFY_MESSAGE",
  getContact: () => ({ type: actions.GET_CONTACTS }),
  getChats: id => ({ type: actions.GET_CHATS, id }),
  getChatsSuccess: data => ({ type: actions.GET_CHATS_SUCCESS, data }),
  getContactSuccess: data => ({ type: actions.GET_CONTACTS_SUCCESS, data }),
  getAudio: (audio, userId, handler, id) => ({
    type: actions.UPLOAD_AUDIO,
    audio,
    userId,
    handler,
    id
  }),
  uploadSuccess: data => ({ type: actions.UPLOAD_AUDIO_SUCCESS, data }),
  clearChat: () => ({ type: actions.CLEAR_CHAT }),
  changeReadStatus: readStatus => ({
    type: actions.CHANGE_STATUS,
    readStatus: readStatus
  }),
  getChannel: (id, chId) => ({ type: actions.GET_CHANNEL, id, chId }),
  setChannelId: id => ({ type: actions.SET_CHANEL_ID, id }),
  getChannels: () => ({ type: actions.GET_CHANNELS }),
  getChannelSuccess: data => ({ type: actions.GET_CHANNELS_SUCCESS, data }),
  clearChannel: id => ({ type: actions.CLEAR_CHANNEL, id }),
  notifyMessage: id => ({ type: actions.NOTIFY_MESSAGE, id })
};

export default actions;
