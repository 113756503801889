import React from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import { Box, Button, FormHelperText } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import useIsMountedRef from 'src/hooks/useIsMountedRef';

import authAction from 'src/redux/auth/actions';
import { useDispatch } from 'react-redux';

const { login } = authAction;

const FormLogin = ({ className, ...rest }) => {
  const dispatch = useDispatch();
  const isMountedRef = useIsMountedRef();

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        submit: null
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email('Debe ser un correo válido')
          .max(255)
          .required('Se requiere un correo'),
        password: Yup.string().max(255).required('Se requiere una contraseña')
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          const payload = {
            rememberme: true,
            username: values.email,
            password: values.password
          };
          await dispatch(login(payload, setErrors));

          if (isMountedRef.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err) {
          console.error(err);
          if (isMountedRef.current) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }
      }}
    >
      {({ errors, isSubmitting }) => {
        return (
          <Form>
            <Field
              fullWidth
              component={TextField}
              label="Correo"
              name="email"
              type="email"
              margin="normal"
            />
            <Field
              fullWidth
              component={TextField}
              label="Contraseña"
              name="password"
              type="password"
              margin="normal"
            />
            {errors.submit && (
              <Box mt={3}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}

            <Box mt={6} display="flex" justifyContent="center">
              <Button
                color="secondary"
                size="large"
                type="submit"
                variant="outlined"
                disabled={isSubmitting}
              >
                Iniciar sesión
              </Button>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

FormLogin.propTypes = {
  className: PropTypes.string
};

export default FormLogin;
