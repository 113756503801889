import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import UK from 'src/images/flag/uk.svg';
import SP from 'src/images/flag/spain.svg';
import { getProfile } from 'src/redux/auth/selectors';
import { getAppLang } from 'src/redux/languageSwitcher/selectors';

import {
  Avatar,
  Tooltip,
  Menu,
  MenuItem,
  IconButton,
  makeStyles
} from '@material-ui/core';

import langActions from 'src/redux/languageSwitcher/actions';

const { changeLanguage } = langActions;

const useStyles = makeStyles((theme) => ({
  avatar: {
    /*height: 32,
    width: 32,*/
    width: theme.spacing(3),
    height: theme.spacing(3),
    border: `2px solid ${theme.palette.background.paper}`
  },
  flag: {
    height: 38,
    width: 38
  },
  popover: {
    width: 200
  }
}));

const Language = () => {
  const classes = useStyles();
  const ref = useRef(null);
  const dispatch = useDispatch();
  const user = useSelector(getProfile());
  const appLang = useSelector(getAppLang());
  const [isOpen, setOpen] = useState(false);
  const [flag, setFlag] = useState(SP);

  useEffect(() => {
    if (appLang) {
      switch (appLang.languageId) {
        case 'spanish':
          setFlag(SP);
          break;
        default:
          setFlag(UK);
      }
    }
  }, [appLang]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeLang = (lang) => {
    dispatch(changeLanguage(lang, user._id));

    handleClose();
  };

  return (
    <>
      <Tooltip title="Languages">
        <IconButton color="inherit" ref={ref} onClick={handleOpen}>
          <Avatar className={classes.avatar}>
            <img src={flag} alt="" className={classes.flag} />
          </Avatar>
        </IconButton>
      </Tooltip>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem onClick={() => handleChangeLang('spanish')}>Español</MenuItem>
        <MenuItem onClick={() => handleChangeLang('english')}>English</MenuItem>
      </Menu>
    </>
  );
};

export default Language;
