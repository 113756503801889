import { all, call, takeEvery, put, fork } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import actions from './actions';
import API from 'src/utils/API';
import Notification from 'src/_old/components/notification';
import { saveAs } from 'src/utils/FileSaver';
import { msToTime } from 'src/utils/utility';
import { v4 as uuid } from 'uuid';
import { split } from 'lodash-es';

let sgUrls = { fileIds: [], done: false };

const JSZip = window.JSZip;
const JSZipUtils = window.JSZipUtils;

export const getFilesId = () => {
  return sgUrls;
};

export function* getProjectAsset(assetId) {
  try {
    const asset = yield call(API.assets.get, assetId);
    if (asset) {
      yield put({
        type: actions.GET_ASSET_SUCCESS,
        asset: asset.url
      });
    } else {
    }
  } catch (err) {
    Notification('error', 'Invalid request', err.error);
  }
}

export function* getFxTrack(assetId) {
  try {
    const asset = yield call(API.assets.get, assetId);
    if (asset) {
      yield put({
        type: actions.GET_ASSET_FX_SUCCESS,
        fxTrack: asset.url
      });
    } else {
    }
  } catch (err) {
    Notification('error', 'Invalid request', err.error);
  }
}

export function* getSubtitles(pId) {
  try {
    const subtitles = yield call(API.subtitles.list, pId);
    if (subtitles) {
      yield put({
        type: actions.GET_SUBTITLES_SUCCESS,
        subtitles: subtitles.texts
      });
    } else {
    }
  } catch (err) {
    Notification('error', 'Invalid request', err.error);
  }
}

export function* getCharAssigned(pId) {
  try {
    const chars = yield call(API.project.getCharAssigned, pId);
    if (chars) {
      yield put({
        type: actions.GET_CHARS_SUCCESS,
        chars: chars
      });
    } else {
    }
  } catch (err) {
    Notification('error', 'Invalid request', err.error);
  }
}

export function* getProjectInfo() {
  yield takeEvery(actions.GET_PROJECT_INFO, function* (action) {
    try {
      const projectInfo = yield call(API.project.details, action.pId);
      let hasFx = false;
      if (projectInfo) {
        // console.log("projectInfo", projectInfo);
        // console.log("action", action);

        const chars = yield call(API.project.getCharAssigned, action.pId);
        const video =
          projectInfo &&
          projectInfo.kind !== 'AUDIOBOOK' &&
          projectInfo.kind !== 'RADIOSPOT'
            ? yield call(API.assets.get, projectInfo.videoFile._id)
            : undefined;

        const subtitles = yield call(API.subtitles.list, action.pId);

        if (projectInfo.fxFile) {
          hasFx = true;
        }

        yield put({
          type: actions.GET_PROJECT_INFO_SUCCESS,
          project: projectInfo,
          asset: video ? video.url : undefined,
          hasFx,
          subtitles: subtitles.texts,
          chars: chars
        });
      }
    } catch (err) {
      Notification('error', err.error ? err.error : 'Invalid request');
    }
  });
}

export function* getSavedRecordings() {
  yield takeEvery(actions.GET_SAVED_TRACKS, function* (action) {
    try {
      const project = yield call(
        API.characters.fetchAudio,
        action.pId,
        action.cId
      );
      yield put({
        type: actions.FETCH_RECORDINGS_SUCCESS,
        recordings: project.recordings
      });
    } catch (err) {
      Notification('error', 'Invalid request', err.error);
    }
  });
}

export function* saveRecording() {
  yield takeEvery(actions.SAVE_RECORDING, function* (action) {
    try {
      sgUrls = { fileIds: [], done: false };

      const takeLength = action.takeLength;
      let splits = action.data.splits;
      const splitsLength = splits.length;
      const fileArrLength = action.fileArr.length;
      const lengthDiff = splitsLength - fileArrLength;

      if (action.fileArr && action.fileArr.length > 0) {
        const payload = {
          files: action.fileArr
        };
        const files = yield call(
          API.common.getSignedURLArray,
          action.data.projectId,
          payload
        );

        files.forEach((file, index) => {
          splits[lengthDiff + index].file = file._id;
          sgUrls.fileIds.push(file._id);
        });

        sgUrls.done = true;

        console.log('News urlId:', sgUrls);

        yield files.map((file, index) => {
          console.log('Upload:', file);
          return call(API.common.uploadToS3, file.url, action.wavArr[index]);
        });
      }

      const characterId = action.data.characterId;
      const projectId = action.data.projectId;

      const myRecordings = yield call(
        API.characters.fetchAudio,
        projectId,
        characterId
      );

      const recordingList = myRecordings.recordings;
      let recordings = recordingList;

      if (recordingList.length > 0) {
        const records = yield recordingList.map((recording) =>
          recording.map((element) => {
            const split = {
              part: element.part,
              file: element.fileId,
              take: element.take,
              start: element.start,
              cueIn: element.cueIn,
              cueOut: element.cueOut,
              waveId: element.waveId || uuid(),
              name: element.name,
              transcript: element.transcript
            };
            return split;
          })
        );
        recordings = records;
      } else {
        for (let i = 0; i < takeLength; i++) {
          recordings[i] = [];
        }
      }
      if (splits && !splits.length) {
        recordings[action.data.takeNo - 1] = [];
      } else {
        recordings[action.data.takeNo - 1] = splits;
      }

      const data = {
        recordings,
        lastUpdatedTake: action.data.takeNo
      };
      // console.log('DATA', data);

      yield call(API.project.saveRecording, data, projectId, characterId);
      yield call(action.cb, 'success', 'page.workSaved');
    } catch (err) {
      yield call(action.cb, 'error', 'page.workSubmitFailed');
    }
  });
}
export function* submitFile() {
  yield takeEvery(actions.SUBMIT_FILE, function* (action) {
    try {
      const takeLength = action.takeLength;
      let splits = action.data.splits;
      const splitsLength = splits.length;
      const fileArrLength = action.fileArr.length;
      const lengthDiff = splitsLength - fileArrLength;

      if (action.fileArr && action.fileArr.length > 0) {
        const payload = {
          files: action.fileArr
        };

        const files = yield call(
          API.common.getSignedURLArray,
          action.data.projectId,
          payload
        );
        /*
        files.map((file, index) => {
          splits[lengthDiff + index].file = file._id;
        });
        */
        files.forEach((file, index) => {
          splits[lengthDiff + index].file = file._id;
        });

        yield files.map((file, index) =>
          call(API.common.uploadToS3, file.url, action.wavArr[index])
        );
      }

      const characterId = action.data.characterId;
      const projectId = action.data.projectId;

      const myRecordings = yield call(
        API.characters.fetchAudio,
        projectId,
        characterId
      );
      const recordingList = myRecordings.recordings;
      let recordings = recordingList;
      if (recordingList.length > 0) {
        const records = yield recordingList.map((recording) =>
          recording.map((element) => {
            const split = {
              part: element.part,
              file: element.fileId,
              take: element.take,
              start: element.start,
              cueIn: element.cueIn,
              cueOut: element.cueOut,
              //  end: element.start + element.cueOut - element.cueIn,
              waveId: element.waveId || uuid(),
              name: element.name,
              transcript: element.transcript
            };
            return split;
          })
        );
        recordings = records;
      } else {
        for (let i = 0; i < takeLength; i++) {
          recordings[i] = [];
        }
      }

      recordings[action.data.takeNo - 1] = splits;
      const data = {
        recordings,
        lastUpdatedTake: action.data.takeNo
      };
      //const submit =
      yield call(API.project.submit, data, projectId, characterId);

      yield call(action.cb, 'success', 'page.workSubmitted');
      yield put(push('/projects/dubPending'));
    } catch (err) {
      yield call(action.cb, 'error', 'page.workSubmitFailed');
    }
  });
}

export function* submitRetake() {
  yield takeEvery(actions.SUBMIT_RETAKE, function* (action) {
    try {
      let splits = action.data.splits;
      const splitsLength = splits.length;
      const fileArrLength = action.fileArr.length;
      const lengthDiff = splitsLength - fileArrLength;
      let files;

      if (action.fileArr && action.fileArr.length > 0) {
        const payload = {
          files: action.fileArr
        };
        files = yield call(
          API.common.getSignedURLArray,
          action.data.projectId,
          payload
        );
        /*
        files.map((file, index) => {
          splits[lengthDiff + index].file = file._id;
        });
        */

        files.forEach((file, index) => {
          splits[lengthDiff + index].file = file._id;
        });

        yield files.map((file, index) =>
          call(API.common.uploadToS3, file.url, action.wavArr[index])
        );
      }

      const characterId = action.data.characterId;
      const projectId = action.data.projectId;

      const myRecordings = yield call(
        API.characters.fetchAudio,
        projectId,
        characterId
      );
      const recordingList = myRecordings.recordings;
      let recordings = recordingList;
      if (recordingList.length > 0) {
        const records = yield recordingList.map((recording) =>
          recording.map((element) => {
            const split = {
              part: element.part,
              file: element.fileId,
              take: element.take,
              start: element.start,
              cueIn: element.cueIn,
              cueOut: element.cueOut,
              waveId: element.waveId || uuid(),
              name: element.name,
              transcript: element.transcript
            };
            return split;
          })
        );
        recordings = records;
      }

      recordings[action.retakeInfo.take - 1] = splits;
      const data = {
        recordings,
        retakeId: action.retakeInfo._id
      };

      //const submit =
      yield call(API.project.submitRetake, data, projectId, characterId);

      yield call(action.cb, 'success', 'page.retakeSubmitted');
      yield put(push('/dashboard/retakes'));
    } catch (err) {
      yield call(action.cb, 'error', 'page.retakeSubmitFailed');
    }
  });
}

// eslint-disable-next-line
export function* sortRecordings(chars) {
  let playList = [];
  chars.forEach((element) => {
    playList = playList.concat(element.recordings);
  });
  return { list: playList };
}

/*
 * Function to format the recording data
 */
// eslint-disable-next-line
export function* createPlayList(characters, projectObj, takes) {
  const project = {};
  const asset = [];
  project.project = projectObj.title;
  // configuring sampe rate and bit depth
  project.sampleRate = 48000;
  project.bitDepth = 24;

  // Looping characters first
  project.characters = [];

  //console.log("TODO", characters);
  characters.forEach((character) => {
    let charRecs = Object.assign([], character.recordings);
    //console.log("charRecsI", charRecs);

    let i = 0;
    while (charRecs[i] && !charRecs[i].length) i++;
    //  console.log("i", i);

    while (i < charRecs.length && charRecs[i].length) {
      const char = {};
      char.name = charRecs[i][0].name;
      console.log('Personaje:', char.name);

      let sameRecs = charRecs.map((chr) => {
        return chr.filter((c) => c.name === char.name);
      });

      console.log('sameRecs', sameRecs);

      let charRecords = [];
      let startOfTake = 0;
      // Looping recordings of each characters
      console.log('takes', takes);

      sameRecs.forEach((recording, index) => {
        let sorted = recording;

        // "startOfTake" es el comienzo de cada take,
        // which is added to the start and end points of each recording in respective takes

        switch (projectObj.kind) {
          case 'MOVIE':
          case 'TVSPOT':
            startOfTake = takes[index] ? takes[index] : 0;
            if (startOfTake > 0) {
              // subtract 5sec because while slicing we are adding extra 5 sec in the front.
              startOfTake = startOfTake - 5;
            }
            //     console.log('recording', recording);
            console.log('startOfTake', index, startOfTake);
            sorted = recording.map((obj) => {
              obj.start = obj.start + startOfTake;
              obj.end = obj.start + obj.cueOut - obj.cueIn;
              console.log('OBJ', obj);

              return obj;
            });
            break;
          case 'AUDIOBOOK':
          case 'RADIOSPOT':
            recording.sort(function (a, b) {
              return b.start - a.start;
            });
            sorted = recording.map((obj) => {
              obj.start = obj.start + startOfTake;
              obj.end = obj.start + obj.cueOut - obj.cueIn;
              return obj;
            });
            startOfTake = sorted[0] ? sorted[0].end : 0;

            break;
          default:
            break;
        }
        charRecords = [...charRecords, ...sorted];
      });

      charRecords.forEach((c) => {
        const fileUri = c.file;

        //      const m = fileUri.toString().match(/.*\/(.+?)\./);
        const m = fileUri.toString().split('/')[6].split('.wav')[0];
        console.log('fileUri', m);

        if (m && m.length > 1) {
          //          c.file = `assets/${m[1]}-${c.part}.wav`;
          c.file = `assets/${m}-${c.part}.wav`;
        } else {
          c.file = '';
        }

        delete c.fileId;
        delete c.part;
        delete c.take;
        delete c._id;
        //  console.log('fileUri 2', c.file, fileUri);
        asset.push(fileUri);
      });

      // Sorting the recordings based on start time
      charRecords.sort(function (a, b) {
        return a.start - b.start;
      });

      char.recordings = charRecords;
      project.characters.push(char);

      charRecs = charRecs.map((chr) => {
        return chr.filter((c) => c.name !== char.name);
      });
      while (i < charRecs.length && !charRecs[i].length) i++;
      //      console.log("i", i);

      console.log('charRecsN', charRecs);
    }
  });

  console.log('project.characters', project.characters);

  return { playList: project, asset };
}

export function* getAllRecordings() {
  yield takeEvery(actions.GET_RECORDINGS, function* (action) {
    try {
      const project = yield call(API.project.exportDetails, action.projectId);
      const subtitles = yield call(API.subtitles.list, action.projectId);
      const takes = subtitles.texts.reduce(function (result, current) {
        result[current.take] = result[current.take] || {};
        // in seconds
        if (current.seekPoint) {
          result[current.take] = current.seekPoint / 1000;
        }
        return result;
      }, {});

      const taketxt = subtitles.texts.reduce(function (result, current) {
        result[current.take] = result[current.take] || undefined;

        if (current.seekPoint) {
          result[current.take] = msToTime(current.seekPoint);
        }

        return result;
      }, []);

      // Fetching all characters
      const chars = yield project.characters
        .filter((ch) => ch.exportable === 'EXPORTABLE')
        .map((char) =>
          call(API.characters.fetchAudio, action.projectId, char._id)
        );
      //        .filter((ch) => ch.exportable === 'EXPORTABLE');
      console.log('chars', chars);
      // method to structure the aaf input
      const { playList, asset } = yield call(
        createPlayList,
        chars,
        project,
        takes
      );
      playList.frameRate = action.frameRate;

      console.log('ACTION', action);

      const aaf = yield call(
        API.project.exportToAAF,
        action.projectId,
        playList
      );
      console.log('AAF 2:', aaf);
      // Function to download all assets for the project
      // aaf.url is the url of generated aaf file in the aws

      const download = yield call(
        downloadAsset,
        asset,
        aaf.url,
        playList,
        taketxt,
        action.cb
      );
      if (download) {
        yield call(action.cb, 'success', 'page.projectExported');
      } else {
        yield call(action.cb, 'error', 'page.exportFailed');
      }
    } catch (err) {
      console.log(err);
      Notification('error', 'Invalid request', err.error);
    }
  });
}

function urlToPromise(url) {
  console.log('urlToPromise URL', url);
  return new Promise(function (resolve, reject) {
    JSZipUtils.getBinaryContent(url, function (err, data) {
      if (err) {
        reject(err);
      } else {
        resolve(data);
      }
    });
  });
}

/*
 * Function to zip all assets for the project
 * We are using JSZip to zip files
 */
export function* downloadAsset(audioFiles, aaf, playList, takes, cb) {
  let txtData = '';
  let midiDataArray = [];
  let midiDataLen = 0;

  takes = takes.filter((item) => item);
  if (takes && takes.length > 0) {
    //Aqui convertir el TXT en MIDI
    console.log('AAF', aaf);
    console.log('TAKES', takes);

    const headerMIDI = {
      chunkType: 'MThd',
      len: [0x00, 0x00, 0x00, 0x06], //0x00000006, 6 en 32 bits
      format: [0x00, 0x01], //0x0001, //Multipista sincrono, 1 o más pistas simultáneas.
      ntrks: [0x00, 0x01], //0x0001,  //Numero de pistas
      deltaTime: [0x25, 0x80] //0x2580=9600 Microsegundos por cuarto de nota;
    };
    const headerTrack = {
      trackChunks: 'MTrk',
      size: [0x00, 0x00, 0x00, 0x00], //32 bits, tamaño de la pista sin la cabecera. Osea los marcaTakes.
      //-----------------------------
      cero: 0x00, //8 bits
      smptOffsetCmd: [0xff, 0x54, 0x05], //3 bytes
      offset: [0x00, 0x00, 0x00, 0x00], //32 bits 0x00000000
      fractionalFrame: 0x00, //8 bits
      cero2: 0x00, //8 bits
      setTempoCmd: [0xff, 0x51, 0x03], //0xFF5103,
      tempo: [0x07, 0xa1, 0x20] //0x07A120=500000us=0.5seg = 120 bpm.
    };

    let marcaTake = {
      deltaTime7Bits: [0], //0xC0 = 10 ms
      markerCmd: [0xff, 0x06], //0xFF06
      textLen: 0x6,
      text: 'Take 1'
    };

    let marcaTakeStr = Object.values(marcaTake);

    // console.log("MarcaTake", marcaTakeStr);
    midiDataLen += 10; //marcaTake.deltaTime7Bits.length + 3 + marcaTake.text.length;
    // console.log("midiDataLen", midiDataLen);

    midiDataArray.push(marcaTakeStr);

    let midiTime = 0;
    //midiData.push.Object.values(marcaTake);

    txtData += `Take 1\t00:00.000\n`;

    takes.forEach((take, index) => {
      txtData += `Take ${index + 2}\t${take + '0'}\n`;

      marcaTake.text = `Take ${index + 2}`;
      marcaTake.textLen = marcaTake.text.length;

      const minSecDec = [];
      take.match(/\d+/g).forEach(function (i, j) {
        minSecDec[j] = parseInt(i);
      });
      //  console.log("minSecDec", minSecDec);

      let deltaTime =
        minSecDec[2] * 0xc0 +
        minSecDec[1] * 0xc0 * 100 +
        minSecDec[0] * 0xc0 * 100 * 60 -
        midiTime;
      midiTime += deltaTime;
      //  console.log("deltaTime", deltaTime);

      marcaTake.deltaTime7Bits = [];
      while (deltaTime > 0x7f) {
        marcaTake.deltaTime7Bits.unshift(deltaTime & 0x7f);
        deltaTime >>>= 7; // >>> mete ceros por la izquierda, >> mete el signo
      }
      marcaTake.deltaTime7Bits.unshift(deltaTime);

      //Pone el ultimo bit de cada elemento a 1 menos en el último.
      for (let n = 0; n < marcaTake.deltaTime7Bits.length - 1; n++) {
        marcaTake.deltaTime7Bits[n] |= 0x80;
      }

      marcaTakeStr = Object.values(marcaTake);

      midiDataLen +=
        marcaTake.deltaTime7Bits.length + 3 + marcaTake.text.length;

      //      console.log("midiDataLen", midiDataLen);

      midiDataArray.push(marcaTakeStr);
      //      midiData.push.Object.values(marcaTake);
    });

    //Fin de Pista
    midiDataArray.push([0x00, 0xff, 0x2f, 0x00]);

    midiDataLen += 4 + 16;
    //    console.log("midiDataLen", midiDataLen);

    //Mete el tamaño de la pista
    headerTrack.size[0] = midiDataLen >> 24;
    headerTrack.size[1] = (midiDataLen >> 16) & 0xff;
    headerTrack.size[2] = (midiDataLen >> 8) & 0xff;
    headerTrack.size[3] = midiDataLen & 0xff;

    midiDataArray.unshift(Object.values(headerTrack));
    midiDataArray.unshift(Object.values(headerMIDI));

    midiDataLen += 14 + 8;
  }

  //  console.log("MIDI DATA", midiDataArray);

  var midiData = new ArrayBuffer(midiDataLen);
  var view = new DataView(midiData);

  // console.log("MIDI LEN", midiDataLen);

  let pos = 0;
  midiDataArray.forEach((obj) => {
    obj.forEach((o) => {
      switch (typeof o) {
        case 'string':
          for (let i = 0; i < o.length; i++) {
            view.setUint8(pos + i, o.charCodeAt(i));
          }
          pos += o.length;
          break;

        case 'object':
          for (let i = 0; i < o.length; i++) {
            view.setUint8(pos + i, o[i]);
          }
          pos += o.length;
          break;

        case 'number':
          view.setUint8(pos, o);
          pos++;
          break;
        default:
      }
    });
  });

  //  return;

  const zipFileName = `${
    playList.project ? playList.project.replace(/ /g, '_') : 'project'
  }`;

  const zip = new JSZip();
  zip.file(
    //   `${playList.project ? playList.project.replace(/ /g, '_') : 'project'}.aaf`,
    `${zipFileName}.aaf`,
    urlToPromise(aaf),
    { binary: true }
  );

  console.log('zipFileName -->', zipFileName);

  // Checking if takes are there
  if (takes && takes.length > 0) {
    zip.file(`${zipFileName}-takes.txt`, new Blob([txtData]), { binary: true });

    zip.file(`${zipFileName}-takes.mid`, new Blob([midiData]), {
      binary: true
    });
  }

  playList.characters
    .reduce((acc, curr) => {
      return acc.concat(curr.recordings);
    }, [])
    .reduce((acc, curr) => {
      console.log('CURR file:', curr.file);
      acc.push(curr.file.replace('assets/', ''));
      return acc;
    }, [])
    .forEach((file) => {
      console.log('FILE', file);
      //const matchRecordingFile = file.toString().match(/(.*-.*-.*-.*-.*)-.*/)
      const matchRecordingFile =
        file.toString().match(/(.*_.*_.*)-.*/) ||
        file.toString().match(/(.*-.*-.*-.*-.*)-.*/);
      const audioFilesFiltered = audioFiles.filter((audioFile) => {
        //const matchAudioFile = audioFile.toString().match(/.*\/(.+?)\./);
        console.log('audioFile:', audioFile);
        const matchAudioFile = audioFile
          .toString()
          .split('/')[6]
          .split('.wav')[0];

        //      return matchAudioFile[1] === matchRecordingFile[1];
        return matchAudioFile === matchRecordingFile[1];
      });
      const audioFile = audioFilesFiltered[0];
      const fileName = `assets/${file}`;
      zip.file(fileName, urlToPromise(audioFile), { binary: true });
    });

  console.log('LLEGA');

  // when everything has been downloaded, we can trigger the dl
  const saving = yield zip
    .generateAsync({ type: 'blob' }, function updateCallback(metadata) {
      // eslint-disable-next-line
      let msg = 'progress : ' + metadata.percent.toFixed(2) + ' %';
      if (metadata.currentFile) {
        msg += ', current file = ' + metadata.currentFile;
      }
      //      console.log('MSG', msg);
    })
    .then(
      (blob) => {
        // console.log('Saving finished...');
        saveAs(blob, `${zipFileName}.zip`);
        return true;
      },
      function (e) {
        console.log(e);
        return false;
      }
    );
  return saving;
}

export function* qaUpdateRecording() {
  yield takeEvery(actions.QA_UPDATE_RECORDING, function* (action) {
    try {
      //   console.log('qaUpdateRecording ', action);
      const payloads = [];
      yield Object.keys(action.recordings).forEach(function (characterId) {
        const splits = action.recordings[characterId].map((recording) => {
          return {
            waveId: recording.waveId || uuid(),
            start: recording.startTime,
            cueIn: recording.cueIn,
            cueOut: recording.cueOut,
            take: recording.take,
            part: recording.part,
            file: recording.fileId,
            name: recording.name,
            transcript: recording.transcript
          };
        });
        payloads.push({
          splits: splits,
          characterId: characterId
        });
      });
      //   console.log('payloads ', payloads);

      const allCharacters = yield call(
        API.characters.fetchAudios,
        action.projectId
      );
      //     console.log('allCharacters ', allCharacters);

      //payloads.map((payload, index) => {
      payloads.forEach((payload, index) => {
        let recordingList = allCharacters.filter(
          (rec) => rec._id === payload.characterId
        );

        //    console.log('recordingList ', recordingList);

        const records = recordingList[0].recordings.map((recording) =>
          recording.map((element) => {
            const split = {
              part: element.part,
              file: element.fileId,
              take: element.take,
              start: element.start,
              cueIn: element.cueIn,
              cueOut: element.cueOut,
              waveId: element.waveId || uuid(),
              name: element.name,
              transcript: element.transcript
            };
            //    console.log('split', split);
            return split;
          })
        );

        records[action.takeNo - 1] = payload.splits;
        console.log('Records:', records);
        payload.splits = records;
      });

      yield payloads.map((payload, index) =>
        call(
          API.project.saveRecording,
          {
            recordings: payload.splits,
            lastUpdatedTake: action.takeNo
          },
          action.projectId,
          payload.characterId
        )
      );
      //      yield call(action.cb);
      yield call(action.cb, 'success', 'page.workSaved');
    } catch (err) {
      yield call(action.cb, 'error', 'page.workSubmitFailed');
    }
  });
}

export function* replaceRecording() {
  yield takeEvery(actions.REPLACE_RECORDING, function* (action) {
    try {
      const file = yield call(API.project.replaceRecording, action.assetId);
      yield call(API.common.uploadToS3, file.url, action.file);
    } catch (err) {
      yield call(action.cb);
      Notification('error', 'Cannot submit your work!');
    }
  });
}

export function* batchRetake() {
  yield takeEvery(actions.BATCH_RETAKE, function* (action) {
    try {
      console.log(action);
      // This api call is for making the project live
      yield call(API.retakes.batchSave, action.payload, action.pId);
      yield put(push('/dashboard/exportPending'));
    } catch (err) {
      console.log(err);
      Notification('error', 'Invalid request', err.error);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getProjectInfo),
    fork(getSavedRecordings),
    fork(saveRecording),
    fork(replaceRecording),
    fork(qaUpdateRecording),
    fork(submitFile),
    fork(submitRetake),
    fork(getAllRecordings),
    fork(batchRetake)
  ]);
}
