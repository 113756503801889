import { all, call, takeEvery, put, fork, take } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { clearToken, updateProfile } from 'src/utils/utility';
import actions, {
  ActionTypes,
  uploadFailure,
  uploadProgress,
  uploadSuccess
} from './actions';
import API from 'src/utils/API';
import Notification from 'src/_old/components/notification';

export function* getProjectAssetList() {
  yield takeEvery(actions.LIST_PROJECTS, function* (action) {
    try {
      const list = yield call(API.project.listProjectAssets, action.payload);
      if (list) {
        yield put({
          type: actions.LIST_PROJECTS_SUCCESS,
          projects: list
        });
      }
    } catch (err) {
      Notification('error', 'Invalid request', err.error);
    }
  });
}

export function* getNotificationList() {
  yield takeEvery(actions.LIST_NOTIFICATIONS, function* (action) {
    try {
      const notification_list = yield call(API.project.notifications);
      console.log('notification_list', notification_list);
      if (notification_list) {
        yield put({
          type: actions.LIST_NOTIFICATIONS_SUCCESS,
          notifications: notification_list.data,
          totalCount: notification_list.totalCount
        });
      }
    } catch (err) {
      if (err.error === 'Invalid token!') {
        clearToken();
        yield put(push('/'));
      }
      Notification('error', 'Invalid request', err.error);
    }
  });
}

export function* projectFeedback() {
  yield takeEvery(actions.PROJECT_FEEDBACK, function* (action) {
    try {
      const data = { action: action.payload.action };
      const project_feedback = yield call(
        API.project.projectFeedback,
        action.payload,
        data
      );
      if (project_feedback) {
        const notification_list = yield call(API.project.notifications);
        if (notification_list) {
          yield put({
            type: actions.LIST_NOTIFICATIONS_SUCCESS,
            notifications: notification_list
          });
        }
        if (action.payload.role === 'TRANSLATOR') {
          if (action.payload.action === 'ACCEPT') {
            yield put(push(`/projects/${action.payload.projectId}/translate`));
          } else {
            yield put(push('/projects/translationPending'));
          }
        } else {
          if (action.payload.action === 'ACCEPT') {
            yield put(
              push(
                `/projects/${action.payload.projectId}/studio/${action.payload.characterId}`
              )
            );
          } else {
            yield put(push('/projects/invitationPending'));
          }
        }
      }
    } catch (err) {
      Notification('error', 'Invalid request', err.error);
    }
  });
}

export function* readNotification() {
  yield takeEvery(actions.READ_NOTIFICATION, function* (action) {
    try {
      const isRead = { isRead: action.payload.isRead };
      yield call(API.project.readNotification, action.payload, isRead);
      const notification_list = yield call(API.project.notifications);
      if (notification_list) {
        yield put({
          type: actions.LIST_NOTIFICATIONS_SUCCESS,
          notifications: notification_list.data,
          totalCount: notification_list.totalCount
        });
      }
    } catch (err) {
      Notification('error', 'Invalid request', err.error);
    }
  });
}

export function* createProject() {
  yield takeEvery(actions.CREATE_PROJECTS, function* (action) {
    try {
      yield call(API.project.create, action.payload);
      yield call(
        action.spinHandler,
        false,
        'success',
        'page.projectCreatedSuccess'
      );
      yield put(push('activeProjects'));
    } catch (err) {
      yield call(
        action.spinHandler,
        false,
        'error',
        'page.projectCreatedFailed'
      );
    }
  });
}

export function* editProject() {
  yield takeEvery(actions.UPDATE_PROJECT, function* (action) {
    try {
      console.log(action);
      yield call(API.project.update, action.payload, action.pId);
      yield call(action.spinHandler, false, 'success', 'page.projectUpdated');

      if (action.kind === 'RADIOSPOT' || action.kind === 'TVSPOT') {
        yield put(push(`/projects/${action.pId}/casting`));
      } else {
        yield put(push(`/projects/${action.pId}`));
      }
    } catch (err) {
      yield call(
        action.spinHandler,
        false,
        'error',
        'page.updateProjectFailed'
      );
    }
  });
}

export function* deleteProject() {
  yield takeEvery(actions.DELETE_PROJECT, function* (action) {
    try {
      yield call(API.project.delete, action.projectId);
      yield call(action.spinHandler, 'success', 'page.projectDeleted');

      yield put(actions.deleteProjectSuccess(action.projectId));
    } catch (err) {
      yield call(action.spinHandler, 'error', 'page.deleteProjectFailed');
    }
  });
}

export function* getProjectKinds() {
  yield takeEvery(actions.LIST_PROJECTS_KINDS, function* (action) {
    try {
      const kinds = yield call(API.project.getKinds);
      if (kinds) {
        yield put({
          type: actions.LIST_PROJECTS_KINDS_SUCCESS,
          kinds: kinds
        });
      }
    } catch (err) {
      Notification('error', 'Invalid request', err.error);
    }
  });
}

export function* getUsers() {
  yield takeEvery(actions.LIST_USERS, function* (action) {
    console.log(action);
    try {
      let payload = {};
      if (action && action.payload) {
        payload = action.payload;
      }
      payload.limit = 1000;
      const users = yield call(API.users.list, payload);
      if (users) {
        //  console.log("users", users);
        yield put({
          type: actions.LIST_USERS_SUCCESS,
          users: users
        });
      }
    } catch (err) {
      Notification('error', 'Invalid request', err.error);
    }
  });
}

export function* addCasting() {
  yield takeEvery(actions.ADD_CASTING, function* (action) {
    try {
      yield call(API.project.addCasting, action.payload, action.projectId);
    } catch (err) {
      Notification('error', 'Invalid request', err.error);
    }
  });
}

export function* deleteCasting() {
  yield takeEvery(actions.DELETE_CASTING, function* (action) {
    try {
      yield call(
        API.project.deleteCasting,
        action.projectId,
        action.characterId
      );
    } catch (err) {
      Notification('error', 'Invalid request delete casting', err.error);
    }
  });
}

export function* addFile() {
  yield takeEvery(actions.ADD_FILE, function* (action) {
    console.log('action ', action);
    try {
      const payload = {
        fileName: action.file.name,
        fileType: action.file.type
      };
      const file = yield call(API.common.getSignedURL, payload);
      if (file && file._id) {
        yield put({
          type: actions.SIGNED_URL_SUCCESS,
          id: file._id,
          fileType: action.fileType
        });
        yield call(API.common.uploadToS3, file.url, action.file);
      }
    } catch (err) {
      Notification('error', 'Invalid request', err.error);
    }
  });
}

export function* addFileToProject() {
  yield takeEvery(actions.ADD_FILE_PROJECT, function* (action) {
    console.log('action ', action);
    try {
      const payload = {
        fileName: action.file.name,
        fileType: action.file.type
      };
      const file = yield call(
        API.common.getSignedURLProject,
        action.projectId,
        payload
      );
      if (file && file._id) {
        yield put({
          type: actions.SIGNED_URL_SUCCESS,
          id: file._id,
          fileType: action.fileType
        });
        yield call(API.common.uploadToS3, file.url, action.file);
      }
    } catch (err) {
      Notification('error', 'Invalid request', err.error);
    }
  });
}

export function* fetchpendingExport() {
  const payload = { limit: 5000 };
  yield takeEvery(actions.PENDING_EXPORT, function* (action) {
    try {
      const list = yield call(API.project.exportPendingList, payload);
      if (list) {
        yield put({
          type: actions.PENDING_EXPORT_SUCCESS,
          projects: list
        });
      }
    } catch (err) {
      Notification('error', 'Invalid request', err.error);
    }
  });
}

export function* fetchDubPending() {
  yield takeEvery(actions.PENDING_DUB, function* (action) {
    try {
      const list = yield call(API.project.dub, action.payload);
      if (list) {
        yield put({
          type: actions.PENDING_DUB_SUCCESS,
          projects: list
        });
      }
    } catch (err) {
      Notification('error', 'Invalid request', err.error);
    }
  });
}

export function* fetchOldProjects() {
  yield takeEvery(actions.OLD_PROJECTS, function* (action) {
    try {
      const list = yield call(API.project.old, action.payload);
      if (list) {
        console.log('LIST', list);
        yield put({
          type: actions.OLD_PROJECTS_SUCCESS,
          projects: list
        });
      }
    } catch (err) {
      Notification('error', 'Invalid request', err.error);
    }
  });
}

export function* fetchInviPending() {
  yield takeEvery(actions.INVI_PENDING, function* (action) {
    try {
      const list = yield call(API.project.pendingAcceptance);
      if (list) {
        yield put({
          type: actions.INVI_PENDING_SUCCESS,
          projects: list
        });
      }
    } catch (err) {
      Notification('error', 'Invalid request', err.error);
    }
  });
}

export function* clearAllNotifications() {
  yield takeEvery(actions.CLEAR_ALL_NOTIFICATIONS, function* (action) {
    try {
      yield call(API.common.clearAllNotifications);
      const notification_list = yield call(API.project.notifications);
      if (notification_list) {
        yield put({
          type: actions.LIST_NOTIFICATIONS_SUCCESS,
          notifications: notification_list.data,
          totalCount: notification_list.totalCount
        });
      }
    } catch (err) {
      Notification('error', 'Invalid request', err.error);
    }
  });
}

export function* updateProjectState() {
  yield takeEvery(actions.UPDATE_PROJECT_STATE, function* (action) {
    try {
      yield call(API.project.updateState, action.pId, action.payload);
      yield call(action.cb);
    } catch (err) {
      yield call(action.cb);
      Notification('error', 'Invalid request', err.error);
    }
  });
}

export function* changeUser() {
  yield takeEvery(actions.CHANGE_USER, function* (action) {
    try {
      yield call(API.project.changeUser, action.pId, action.payload);
      //yield call(action.cb);
    } catch (err) {
      //yield call(action.cb);
      Notification('error', 'Invalid request', err.error);
    }
  });
}

export function* fetchFinancial() {
  yield takeEvery(actions.LIST_FINANCIAL, function* (action) {
    try {
      const list = yield call(API.project.financial, { limit: 100 });
      //const users = yield call(API.users.list, { limit: 1000, isActive: true });
      if (list) {
        yield put({
          type: actions.LIST_FINANCIAL_SUCCESS,
          financialProjects: list
        });
      }
      /*if (users) {
        yield put({
          type: actions.LIST_USERS_SUCCESS,
          users: users
        });
      }*/
    } catch (err) {
      Notification('error', 'Invalid request', err.error);
    }
  });
}

// Watch for an upload request and then
// defer to another saga to perform the actual upload
export function* uploadRequestWatcherSaga() {
  yield takeEvery(ActionTypes.UPLOAD_REQUEST, function* (action) {
    console.log('action ', action);
    try {
      const payload = {
        fileName: action.file.name,
        fileType: action.file.type
      };
      const file = yield call(
        API.common.getSignedURLProject,
        action.projectId,
        payload
      );
      if (file && file._id) {
        yield put({
          type: actions.SIGNED_URL_SUCCESS,
          id: file._id,
          fileType: action.fileType
        });
        yield call(uploadFileSaga, file.url, action.file, action.spinHandler);
      }
    } catch (err) {
      Notification('error', 'Invalid request', err.error);
    }
  });
}

// Upload the specified file
export function* uploadFileSaga(url, file, spinHandler) {
  const channel = yield call(API.common.createUploadFileChannel, url, file);
  while (true) {
    const { progress = 0, err, success } = yield take(channel);
    if (err) {
      yield call(spinHandler, 'error', `Error al subir ${file.name}: ${err}`);
      yield put(uploadFailure(file, err));
      return;
    }
    if (success) {
      yield call(spinHandler, 'success', `${file.name} subido correctamente`);
      yield put(uploadSuccess(file));
      return;
    }
    yield put(uploadProgress(file, progress));
  }
}

export default function* rootSaga() {
  yield all([
    fork(getProjectAssetList),
    fork(getProjectKinds),
    fork(getUsers),
    fork(createProject),
    fork(addCasting),
    fork(deleteCasting),
    fork(addFile),
    fork(addFileToProject),
    fork(editProject),
    fork(deleteProject),
    fork(getNotificationList),
    fork(readNotification),
    fork(projectFeedback),
    fork(fetchpendingExport),
    fork(fetchDubPending),
    fork(fetchOldProjects),
    fork(fetchInviPending),
    fork(clearAllNotifications),
    fork(updateProjectState),
    fork(changeUser),
    fork(fetchFinancial),
    fork(uploadRequestWatcherSaga)
    //fork(uploadFileSaga)
  ]);
}
