import React, { forwardRef /*, useEffect, useCallback*/ } from 'react';
import { Helmet } from 'react-helmet';
//import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
//import track from 'src/utils/analytics';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core';
import LoadingScreen from 'src/components/common/LoadingScreen';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}));

const Page = forwardRef(({ children, title = '', loading, ...rest }, ref) => {
  //const location = useLocation();
  const classes = useStyles();

  /*const sendPageViewEvent = useCallback(() => {
    track.pageview({
      page_path: location.pathname
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    sendPageViewEvent();
  }, [sendPageViewEvent]);*/

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <div className={classes.root} ref={ref} {...rest}>
          <FormattedMessage id={title}>
            {(val) => (
              <Helmet>
                <title>{val}</title>
              </Helmet>
            )}
          </FormattedMessage>
          {children}
        </div>
      )}
    </>
  );
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string
};

export default Page;
