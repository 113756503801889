import { language } from '../../../config.js';

import englishLang from 'src/images/flag/uk.svg';
import spanishLang from 'src/images/flag/spain.svg';

const config = {
  defaultLanguage: language,
  options: [
    {
      languageId: 'spanish',
      locale: 'es',
      text: 'Español',
      icon: spanishLang
    },
    {
      languageId: 'english',
      locale: 'en',
      text: 'English',
      icon: englishLang
    }
  ]
};

export const getCurrentLanguage = (lang) => {
  let selecetedLanguage = config.options[0];
  config.options.forEach((language) => {
    if (language.languageId === lang) {
      selecetedLanguage = language;
    }
  });
  return selecetedLanguage;
};

export default config;
