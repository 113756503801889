import notification from './feedback/notification';

const createNotification = (type, message, description) => {
  notification[type]({
    message,
    description,
    placement: 'bottomRigth',
    style: { zIndex: 99999 }
  });
};
export default createNotification;
