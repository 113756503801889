import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import FirebaseAuthLogin from './formLogin';
import Copyright from 'src/utils/copyright';
import { Link as RouterLink, Redirect, useHistory } from 'react-router-dom';
import Page from 'src/components/common/Page';
import logo from 'src/images/logo.png';
import bgImage from 'src/images/hollyvooz.jpg';
import { getDefaultRoute } from 'src/utils/utility';
import { getProfile } from 'src/redux/auth/selectors';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh'
  },
  image: {
    backgroundImage: `url(${bgImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    //backgroundSize: 'cover'
    backgroundPosition: 'left'
  },
  imageContainer: {
    height: '100%',
    backgroundImage: `url(${logo})`,
    backgroundRepeat: 'no-repeat',
    height: '175px',
    width: '300px',
    backgroundSize: 'cover'
    //backgroundPosition: 'right top'
  },
  grid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  divider: {
    width: '10%',
    backgroundColor: '#adb0bb'
  }
}));

const LoginView = () => {
  const classes = useStyles();
  const profile = useSelector(getProfile());
  const isLoggedIn = useSelector((state) => state.Auth.get('idToken') !== null);

  if (isLoggedIn) {
    let token = localStorage.getItem('id_token');
    if (token === 'secret token') {
      localStorage.clear();
      token = undefined;
    }

    if (token && jwtDecode(token).exp > Date.now() / 1000) {
      if (profile) {
        const defaultUrl = getDefaultRoute(profile.role.handle);
        const from = { pathname: defaultUrl };
        return <Redirect to={from} />;
      } else {
        localStorage.clear();
        token = undefined;
      }
    } else if (token && jwtDecode(token).exp < Date.now() / 1000) {
      localStorage.clear();
      token = undefined;
    }
  }

  return (
    <Page className={classes.root} title="Login">
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={7} md={9} className={classes.image}></Grid>
        <Grid
          item
          xs={12}
          sm={5}
          md={3}
          component={Paper}
          elevation={6}
          square
          className={classes.grid}
        >
          <div className={classes.paper}>
            <Box mb={8}>
              <div className={classes.imageContainer} />
            </Box>
            <Typography color="textPrimary" gutterBottom variant="h2">
              Entrar
            </Typography>
            <Divider variant="middle" className={classes.divider} />
            <Box mt={2}>
              <FirebaseAuthLogin />
            </Box>
            <Box mt={6} display="flex" justifyContent="center">
              <Link
                component={RouterLink}
                to="/password-recovery"
                underline="none"
              >
                Olvidaste la contraseña?
              </Link>
            </Box>
            <Box mt={6}>
              <Copyright />
            </Box>
          </div>
        </Grid>
      </Grid>
    </Page>
  );
};

export default LoginView;
