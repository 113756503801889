import { all, call, takeEvery, put, fork } from 'redux-saga/effects';
import API from 'src/utils/API';

import actions from './actions';

export function* default_saga() {
  yield takeEvery(actions.GET_CONTACTS, function* () {
    try {
      const result = yield call(API.chats.requestContacts);
      yield put(actions.getContactSuccess(result));
    } catch (err) {
      throw err;
    }
  });
  yield takeEvery(actions.UPLOAD_AUDIO, function* (action) {
    try {
      let payload = {
        fileName: action.audio.name,
        fileType: action.audio.type,
        assetType: 'CHAT'
      };
      const result = yield call(API.chats.uploadAudio, payload);
      if (result && result._id) {
        const chatResult = yield call(
          API.common.uploadToS3,
          result.url,
          action.audio
        );
        if (chatResult) {
          payload = {
            asset: result._id
          };
          const toUserResult = yield call(
            API.chats.channel,
            payload,
            action.id
          );
          if (toUserResult) {
            yield put(actions.uploadSuccess(toUserResult.data));
            yield call(action.handler);
          }
        }
      }
    } catch (err) {
      throw err;
    }
  });
  yield takeEvery(actions.GET_CHATS, function* (action) {
    try {
      let payload = {
        isRead: true,
        channelId: action.id
      };
      const result = yield call(API.chats.getChats, action.id);
      if (result) {
        yield call(API.chats.markRead, payload);
        yield put(actions.getChatsSuccess(result.data));
        const resultStatus = yield call(API.chats.getChannels);
        if (resultStatus) {
          yield put(actions.changeReadStatus(resultStatus.readStatus));
        }
      }
    } catch (err) {
      throw err;
    }
  });

  yield takeEvery(actions.GET_CHANNEL, function* (action) {
    try {
      if (action.id !== null) {
        const payload = {
          toUsers: [action.id]
        };
        const result = yield call(API.chats.channels, payload);
        if (result) {
          yield put(actions.setChannelId(result.data._id));
          yield put(actions.getChannels());
        }
      } else {
        yield put(actions.setChannelId(action.chId));
      }
    } catch (err) {
      throw err;
    }
  });
  yield takeEvery(actions.GET_CHANNELS, function* (action) {
    try {
      const result = yield call(API.chats.getChannels);
      if (result) {
        yield put(actions.getChannelSuccess(result));
        yield put(actions.changeReadStatus(result.readStatus));
      }
    } catch (err) {
      throw err;
    }
  });
  yield takeEvery(actions.CLEAR_CHANNEL, function* (action) {
    try {
      const result = yield call(API.chats.clearChannel, action.id);
      if (result) {
        const resultChats = yield call(API.chats.getChats, action.id);
        if (resultChats) {
          yield put(actions.getChatsSuccess(resultChats.data));
        }
      }
    } catch (err) {
      throw err;
    }
  });
  yield takeEvery(actions.NOTIFY_MESSAGE, function* (action) {
    try {
      let payload = {
        isRead: true,
        channelId: action.id
      };
      const result = yield call(API.chats.markRead, payload);
      if (result) {
        yield put(actions.getChannels());
      }
    } catch (err) {
      throw err;
    }
  });
}

export default function* rootSaga() {
  yield all([fork(default_saga)]);
}
