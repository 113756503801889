import React from 'react';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { siteConfig } from 'src/config';

export default () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://hollyvooz.com">
        {siteConfig.siteName}
      </Link>{' '}
      2019 - {new Date().getFullYear()}
    </Typography>
  );
};
