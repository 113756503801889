import { fromJS } from 'immutable';
import actions from './actions';

const initState = fromJS({
  project: null,
  asset: null,
  hasFx: false,
  subtitles: null,
  chars: null,
  playList: null,
  recordings: null,
  assets: false,
  takes: false
});

export default function ProjectReducer(state = initState, action) {
  switch (action.type) {
    case actions.GET_PROJECT_INFO_SUCCESS:
      return state
        .set('project', action.project)
        .set('asset', action.asset)
        .set('hasFx', action.hasFx)
        .set('subtitles', action.subtitles)
        .set('chars', action.chars)
        .set('playList', action.playList)
        .set('recordings', action.recordings);
    case actions.GET_ASSET_SUCCESS:
      return state.set('asset', action.asset);
    case actions.SET_FX:
      return state.set('hasFx', action.hasFx);
    case actions.GET_SUBTITLES_SUCCESS:
      return state.set('subtitles', action.subtitles);
    case actions.GET_CHARS_SUCCESS:
      return state.set('chars', action.chars);
    case actions.GET_RECORDING_SUCCESS:
      return state
        .set('playList', action.playList)
        .set('asset', action.asset)
        .set('takes', action.takes);
    case actions.FETCH_RECORDINGS_SUCCESS:
      return state.set('recordings', action.recordings);
    default:
      return state;
  }
}
