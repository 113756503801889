import { all, call, takeEvery, put, fork } from 'redux-saga/effects';
import actions from './actions';
import API from 'src/utils/API';
import authActions from '../auth/actions';
import Notification from 'src/_old/components/notification';
import { getToken, getProfile } from 'src/utils/utility';

export function* updateAppLang() {
  yield takeEvery(actions.CHANGE_LANGUAGE, function* (action) {
    try {
      const payload = {};
      payload.appLang = action.language.languageId;

      yield put({
        type: actions.SET_LANGUAGE,
        language: action.language
      });

      const profileJson = JSON.parse(localStorage.getItem('profile'));
      // console.log('profileJson', profileJson);
      profileJson.appLang = payload.appLang;
      localStorage.setItem('profile', JSON.stringify(profileJson));

      const token = getToken().get('idToken');
      const profile = getProfile().get('profile');

      profile.appLang = payload.appLang;

      yield put({
        type: authActions.LOGIN_SUCCESS,
        token,
        profile
      });
      yield call(API.users.update, payload, action.userId);
    } catch (err) {
      // console.log(err);
      Notification('error', 'Invalid request', err.error);
    }
  });
}

export default function* rootSaga() {
  yield all([fork(updateAppLang)]);
}
