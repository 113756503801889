/**
 * Language selectors
 */

import { createSelector } from 'reselect';

const selectLanguage = (state) => state.LanguageSwitcher;

const getAppLang = () =>
  createSelector(selectLanguage, (langState) => langState.language);

export { getAppLang };
