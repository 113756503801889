const actions = {
  LIST_USERS_BY_ROLE: 'LIST_USERS_BY_ROLE',
  LIST_USERS_BY_ROLE_SUCCESS: 'LIST_USERS_BY_ROLE_SUCCESS',
  LIST_ROLES: 'LIST_ROLES',
  LIST_ROLES_SUCCESS: 'LIST_USERS_ROLES_SUCCESS',
  LIST_ALL_USERS: 'LIST_ALL_USERS',
  LIST_ALL_USERS_SUCCESS: 'LIST_ALL_USERS_SUCCESS',
  LIST_USER_ROLES_SUCCESS: 'LIST_USER_ROLES_SUCCESS',
  LIST_USER_SUCCESS: 'LIST_USER_SUCCESS',
  FETCH_USER: 'FETCH_USER',
  listUsers: (role) => ({
    type: actions.LIST_USERS_BY_ROLE,
    role
  }),
  listAllUsers: () => ({
    type: actions.LIST_ALL_USERS
  }),
  listRoles: () => ({
    type: actions.LIST_ROLES
  }),
  fetchUser: (userId) => ({
    type: actions.FETCH_USER,
    userId
  })
};
export default actions;
