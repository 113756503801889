import { all } from "redux-saga/effects";
import authSagas from "./auth/saga";
import projectSagas from "./Projects/saga";
import studioSagas from "./Studio/saga";
import userSagas from "./Users/saga";
import settingsSagas from "./Settings/saga";
import contactSaga from "./contacts/saga";
import langSaga from "./languageSwitcher/saga";

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    projectSagas(),
    studioSagas(),
    userSagas(),
    settingsSagas(),
    contactSaga(),
    langSaga()
  ]);
}
