import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';
import rtl from 'jss-rtl';
import { create } from 'jss';
import { SnackbarProvider } from 'notistack';
import DateFnsUtils from '@date-io/date-fns';
import { es } from 'date-fns/locale';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { jssPreset, StylesProvider, ThemeProvider } from '@material-ui/core';

import GlobalStyles from 'src/components/common/GlobalStyles';
//import CookiesNotification from 'src/components/common/CookiesNotification';
import useSettings from 'src/hooks/useSettings';
import { createTheme } from 'src/theme';
import PublicRoutes from 'src/router';
import AppLocale from './locales';
import { history } from './redux/store';
import Boot from './redux/boot';
import { getAppLang } from 'src/redux/languageSwitcher/selectors';
import actions from 'src/redux/Users/actions';

const { listRoles } = actions;

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const App = () => {
  const { settings } = useSettings();
  const dispatch = useDispatch();
  const appLang = useSelector(getAppLang());

  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme
  });

  useEffect(() => {
    dispatch(listRoles());
  }, [dispatch]);

  //console.log('Locale', appLang.locale, appLang.locale);

  return (
    <IntlProvider locale={appLang.locale} messages={AppLocale[appLang.locale]}>
      <ThemeProvider theme={theme}>
        <StylesProvider jss={jss}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
            <SnackbarProvider maxSnack={3}>
              <PublicRoutes history={history} />
              {/* <CookiesNotification /> */}
              <GlobalStyles />
            </SnackbarProvider>
          </MuiPickersUtilsProvider>
        </StylesProvider>
      </ThemeProvider>
    </IntlProvider>
  );
};

Boot()
  .then(() => App())
  .catch((error) => console.error(error));

export default App;
export { AppLocale };
