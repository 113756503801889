import { createBrowserHistory } from 'history'
import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { connectRouter, routerMiddleware } from "connected-react-router";
import thunk from "redux-thunk";
import createSagaMiddleware from "redux-saga";
import reducers from "src/redux/reducers";
import rootSaga from "src/redux/sagas";

const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const routeMiddleware = routerMiddleware(history);
const middlewares = [thunk, sagaMiddleware, routeMiddleware];
const enhancers = [];

if (process.env.NODE_ENV === "development") {
  const devToolsExtension = window.devToolsExtension;

  if (typeof devToolsExtension === "function") {
    enhancers.push(devToolsExtension());
  }
}

const store = createStore(
  combineReducers({
    ...reducers,
    router: connectRouter(history)
  }),
  compose(
    applyMiddleware(...middlewares),
    ...enhancers
  )
);
sagaMiddleware.run(rootSaga);
export { store, history };
